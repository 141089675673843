//import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
// @mui
import {
    Card,
    Container,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField
} from '@mui/material';

import CustomizeModal from 'components/Modal/CustomizeModal';
import Search from 'components/Search/Search';
import Iconify from 'components/iconify/Iconify';
import Label from 'components/label/Label';
import { ROWS_PERS_PAGE_OPTIONS } from 'constants/COMMON';
import { VEHICLES_ACTION_EDIT_MODE_PERMISSION, VEHICLES_ACTION_MANUAL_JOB_PERMISSION, VEHICLES_ACTION_MAP_PERMISSION, VEHICLES_ACTION_RESET_PERMISSION } from 'constants/PERMISSIONS';
import { useUser } from 'hooks/useUser';
import { useHistory } from 'react-router';
import { compareIgnoreCase, pageCountDisplay } from 'utils/utilFunctions';
import TableHeader from '../../components/TableHeader/TableHeader';
import JobsService from '../../services/jobs.service';
import PositionService from '../../services/position.service';
import VehicleService from '../../services/vehicle.service';
import './Vehicles.css';
import { VEHICLES_GET_POSITION_PERMISSION } from 'constants/PERMISSIONS';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Vehicle ID', alignRight: false },
    { id: 'mode', label: 'Mode', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'autonomy', label: 'Autonomy', alignRight: false },
    { id: 'handshake', label: 'Handshake', alignRight: false },
    { id: 'tosId', label: 'TOS ID', alignRight: false },
    { id: 'fmsId', label: 'FMS ID', alignRight: false },
];

// ----------------------------------------------------------------------
function sortPositionsByTosId(positions) {
    return [...positions].sort((a, b) => a.tosId.localeCompare(b.tosId))
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (item) =>
            item.id.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1
            || !!item.status && item.status.toLowerCase().indexOf(query.toLowerCase()) !== -1
            || !!item.tosId && item.tosId.toLowerCase().indexOf(query.toLowerCase()) !== -1
            || !!new String(item.handshake) && new String(item.handshake).toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

function getColorByStatus(status) {
    switch (status) {
        case 'OFF': return 'default';
        case 'AUTO': return 'success';
        case 'MANUAL': return 'primary';
        case 'REMOTE': return 'secondary';
        default: return 'primary';
    }
}

export default function Vehicles() {
    const positionService = new PositionService();
    const vehicleService = new VehicleService();
    const jobsService = new JobsService();

    const { getUser, getUserPermissions } = useUser();
    const permissions = getUserPermissions();
    const userAsString = getUser();
    const user = JSON.parse(userAsString);

    const history = useHistory();

    const vehicleModes = ['OFF', 'AUTO', 'MANUAL', 'REMOTE'];
    const [open, setOpen] = useState(null);
    const [openManualJobModal, setOpenManualJobModal] = useState(false);
    const [openUpdateVehicleModeModal, setOpenUpdateVehicleModeModal] = useState(false);
    const [openGetVehiclePositionModal, setOpenGetVehiclePositionModal] = useState(false);
    const [currentVehiclePositionPayload, setCurrentVehiclePositionPayload] = useState({});
    const [currentVehiclePosition, setCurrentVehiclePosition] = useState(null)
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [orderBy, setOrderBy] = useState('id');

    const [filterQuery, setFilterQuery] = useState('');
    const [positions, setPositions] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [filteredVehicles, setFilteredVehicles] = useState([]);

    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PERS_PAGE_OPTIONS[0]);

    const [manualJobPayload, setManualJobPayload] = useState({ vehicleId: '', positionId: null });
    const [currentVehicle, setCurrentVehicle] = useState(null);
    useEffect(() => {
        positionService.fetchAll().then(response => setPositions(sortPositionsByTosId(response)));
        vehicleService.fetchAll().then(response => {
            setVehicles(response);
            const filteredAndSortedVehicles = applySortFilter(response, getComparator(order, orderBy), filterQuery);
            setFilteredVehicles(filteredAndSortedVehicles);

        });
    }, []);

    useEffect(() => {
        const filteredAndSortedVehicles = applySortFilter(vehicles, getComparator(order, orderBy), filterQuery);
        setFilteredVehicles(filteredAndSortedVehicles);
    }, [order, orderBy, filterQuery])


    const sortAndFilterData = () => {
        const data = applySortFilter(vehicles, getComparator(order, orderBy), filterQuery);
        setFilteredVehicles([...data]);
    }

    const handleOpenMenu = (event, vehicle) => {
        setManualJobPayload((prev) => ({ ...prev, vehicleId: vehicle.id }));
        setCurrentVehicle(vehicle);
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setManualJobPayload({ vehicleId: '', positionId: null });
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleQueryFilter = (event) => {
        const query = event.target.value;
        setPage(0);
        setFilterQuery(query);
        if (query === null || query === '') {
            setFilteredVehicles(vehicles);
            return;
        }
        sortAndFilterData();
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - vehicles.length) : 0;

    const isNotFound = vehicles.length === 0;

    const createNewJob = () => {
        setOpenManualJobModal(true);
    }

    const updateVehicleMode = () => {
        setOpenUpdateVehicleModeModal(true);
    }

    const handleGetVehiclePosition = () => {
        const { fmsId } = currentVehicle;
        vehicleService.getVehiclePosition(fmsId).then(response => {
            setCurrentVehiclePositionPayload(!!response ? response.data.attributes : {});
            setOpenGetVehiclePositionModal(true);
        });
    }

    const handleCloseGetVehiclePositionModal = () => {
        setOpenGetVehiclePositionModal(false);
        setCurrentVehiclePositionPayload({});
        setCurrentVehiclePosition(null);
        setCurrentVehicle(null);
        setOpen(null);
    }

    const handleSaveGetVehiclePositionModal = () => {
        const currentPosition = { ...currentVehiclePosition, latitude: currentVehiclePositionPayload.lat, longitude: currentVehiclePositionPayload.lon };
        positionService.put(currentPosition).then(response => {
            handleCloseGetVehiclePositionModal();
        });
    }

    const handleGetVehiclePositionChange = ($event) => {
        const position = $event.target.value;
        console.log('position : ', position);
        setCurrentVehiclePosition(position);
    }

    const handleCloseManualJobModal = () => {
        setOpenManualJobModal(false);
        setManualJobPayload({ vehicleId: '', positionId: null });
    }

    const handleCloseUpdateVehicleModeModal = () => {
        setOpenUpdateVehicleModeModal(false);
        setCurrentVehicle(null);
    }

    const handleSaveVehicleModeModal = () => {
        const payload = {
            username: user.username,
            vehicle: currentVehicle
        }
        vehicleService.put(payload).then(() => {
            setVehicles(prev => (prev.map(v => v.id !== currentVehicle.id ? { ...currentVehicle } : v)));
            handleCloseUpdateVehicleModeModal();
        });
    }

    const handleSaveManualJobModal = () => {
        const payload = {
            type: 'CREATE_MANUAL_JOB',
            body: JSON.stringify({ ...manualJobPayload, creator: user.username })
        }

        jobsService.post(payload).then(response => {
            handleCloseManualJobModal();
            handleCloseMenu();
        }).catch(err => {
            console.error("Manual Job error : ", err)
        });
    }

    const handleChangePosition = ($event) => {
        const positionId = $event.target.value;
        setManualJobPayload(prev => ({ ...prev, positionId: positionId }));
    }

    const handleChangeVehicleMode = ($event) => {
        const mode = $event.target.value;
        setCurrentVehicle(prev => ({ ...prev, mode: mode }));
    }

    const resetVehicle = () => {
        const payload = {
            username: user.username,
            vehicle: currentVehicle
        }
        vehicleService.put(payload).then((response) => {
            const data = vehicles.map(v => v.id === currentVehicle.id ? currentVehicle : v);
            setVehicles(data);
            setFilteredVehicles(data);
            cancelJobsByVehicleId(currentVehicle.id);
            handleCloseMenu();
        });
    };

    const cancelJobsByVehicleId = (vehicleId) => {
        const body = { vehicleId: vehicleId };
        const payload = { type: 'CANCEL_VEHICLE_JOB', body: JSON.stringify(body) };
        jobsService.put(payload).then(() => {
            setCurrentVehicle(null);
        });
    };

    const goToMapView = () => {
        history.push('/admin/liveMap')
    };

    const rightClickHandler = (event, row) => {
        event.preventDefault();
        setOpen(event.target);
        setCurrentVehicle(row);
    };


    return (
        <>
            <title>Vehicle table</title>

            <Container className='table-container'>
                <Card>
                    <Search filterQuery={filterQuery} placeholder={'Search Vehcile ...'} onFilterChange={handleQueryFilter} isResetButtonVisible={true} />
                    <TableContainer>
                        <Table className='vehicle-table' size='small' stickyHeader={true}>
                            <TableHeader
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={vehicles.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filteredVehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { id, status, autonomy, mode, handshake, tosId, fmsId } = row;
                                    return (
                                        <TableRow hover key={id} onContextMenu={(event) => rightClickHandler(event, row)}>
                                            <TableCell align="left" >{id}</TableCell>

                                            <TableCell align="left">
                                                <Label color={getColorByStatus(mode)}>{mode}</Label>
                                            </TableCell>
                                            <TableCell align="left">{status}</TableCell>
                                            <TableCell align="left">{autonomy}</TableCell>
                                            <TableCell align="left">{new String(handshake)}</TableCell>
                                            <TableCell align="left">{tosId}</TableCell>
                                            <TableCell align="left">{fmsId}</TableCell>
                                            <TableCell align="right">
                                                <IconButton size="large" color="inherit" onClick={($event) => handleOpenMenu($event, row)}>
                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                            {isNotFound && (
                                <TableBody>
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>

                    <TablePagination
                        className='table-pagination'
                        rowsPerPageOptions={ROWS_PERS_PAGE_OPTIONS}
                        component="div"
                        count={vehicles.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={() => pageCountDisplay(vehicles.length, page, rowsPerPage)}

                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {permissions.includes(VEHICLES_ACTION_MAP_PERMISSION) && <MenuItem onClick={goToMapView}>
                    <Iconify icon={'eva:map-fill'} sx={{ mr: 2 }} />
                    Map
                </MenuItem>}

                {permissions.includes(VEHICLES_ACTION_MANUAL_JOB_PERMISSION) && <MenuItem onClick={createNewJob}>
                    <Iconify icon={'eva:plus-circle-fill'} sx={{ mr: 2 }} />
                    New Job
                </MenuItem>}

                {permissions.includes(VEHICLES_ACTION_EDIT_MODE_PERMISSION) && <MenuItem onClick={updateVehicleMode}>
                    <Iconify icon={'ic:baseline-mode'} sx={{ mr: 2 }} />
                    Mode
                </MenuItem>}
                {permissions.includes(VEHICLES_GET_POSITION_PERMISSION) && <MenuItem onClick={handleGetVehiclePosition}>
                    <Iconify icon={'oi:map-marker'} sx={{ mr: 2 }} />
                    Get position
                </MenuItem>}
                {permissions.includes(VEHICLES_ACTION_RESET_PERMISSION) && <MenuItem onClick={resetVehicle}>
                    <Iconify icon={'eva:refresh-fill'} sx={{ mr: 2 }} />
                    Reset
                </MenuItem>}
            </Popover>

            <CustomizeModal open={openManualJobModal}
                title={'Manual job'}
                handleClose={handleCloseManualJobModal}
                handleSave={handleSaveManualJobModal}
                isSaveDisabled={manualJobPayload.positionId === null}
            >
                <form>
                    <div className='job-modal-container'>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='vehicle-manual-job-input'
                                type="text"
                                label='Vehicle'
                                disabled={true}
                                value={manualJobPayload.vehicleId}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <InputLabel id="position-manual-job-select-input-label">
                                Destination
                            </InputLabel>
                            <Select
                                labelId="position-manual-job-select-input-label"
                                label="Destination"
                                id="position-manual-job-select-input"
                                onChange={handleChangePosition}
                                name='position'
                            >
                                {
                                    positions.map((position) =>
                                        <MenuItem key={position.id} value={position.id}>{position.tosId}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>
                </form>
            </CustomizeModal>

            <CustomizeModal open={openGetVehiclePositionModal}
                title={'Get vehicle position'}
                handleClose={handleCloseGetVehiclePositionModal}
                handleSave={handleSaveGetVehiclePositionModal}
                isSaveDisabled={currentVehiclePosition === null}
            >
                <form>
                    <div className='job-modal-container'>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='vehicle-position-id-input'
                                type="text"
                                label='Vehicle'
                                disabled={true}
                                value={currentVehiclePositionPayload.vehicleId ?? 'N/A'}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='vehicle-position-timestamp-input'
                                type="text"
                                label='Timestamp'
                                disabled={true}
                                value={currentVehiclePositionPayload?.lastSeen?.substring(0, 19) ?? 'N/A'}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='vehicle-position-theta-input'
                                type="text"
                                label='Theta'
                                disabled={true}
                                value={currentVehiclePositionPayload.theta ?? 'N/A'}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='vehicle-position-speed-input'
                                type="text"
                                label='Speed'
                                disabled={true}
                                value={currentVehiclePositionPayload.speed ?? 'N/A'}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='vehicle-position-lon-input'
                                type="text"
                                label='Longitude'
                                disabled={true}
                                value={currentVehiclePositionPayload.lon ?? 'N/A'}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='vehicle-position-lat-input'
                                type="text"
                                label='Latitude'
                                disabled={true}
                                value={currentVehiclePositionPayload.lat ?? 'N/A'}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <InputLabel id="vehicle-positions-select-input-label">
                                Override crane coordinates
                            </InputLabel>
                            <Select
                                labelId="vehicle-positions-select-input-label"
                                label="Override crane coordinates"
                                id="vehicle-position-select-input"
                                onChange={handleGetVehiclePositionChange}
                                name='position'
                            >
                                {
                                    positions.filter(position => compareIgnoreCase(position.type, 'CRANE')).map((position) =>
                                        <MenuItem key={position.id} value={position}>{position.tosId}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>
                </form>
            </CustomizeModal>

            <CustomizeModal open={openUpdateVehicleModeModal}
                title={'Update Vehicle Mode'}
                handleClose={handleCloseUpdateVehicleModeModal}
                handleSave={handleSaveVehicleModeModal}
                isSaveDisabled={currentVehicle?.mode === null || currentVehicle?.mode === ''}
            >
                <form>
                    <div className='job-modal-container'>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='vehicle-manual-job-input'
                                type="text"
                                label='Vehicle Id'
                                disabled={true}
                                value={currentVehicle?.id}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <InputLabel id="position-manual-job-select-input-label">
                                Mode
                            </InputLabel>
                            <Select
                                labelId="position-manual-job-select-input-label"
                                label="Mode"
                                id="position-manual-job-select-input"
                                onChange={handleChangeVehicleMode}
                                name='mode'
                                value={currentVehicle?.mode}
                            >
                                {
                                    vehicleModes.map((mode) =>
                                        <MenuItem key={mode} value={mode}>{mode}</MenuItem>
                                    )
                                }

                            </Select>
                        </FormControl>
                    </div>
                </form>
            </CustomizeModal>
        </>
    );
}
