
// react-bootstrap components
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { PARAMETERS_VIEW_PERMISSION, USERS_VIEW_PERMISSION } from "constants/PERMISSIONS";
import { useUser } from "hooks/useUser";
import { useState } from "react";
import {
  Container
} from "react-bootstrap";
import Users from "views/Users/Users";
import Parameters from "./Parameters";
import './Settings.scss';
function Settings() {
  const { getUserPermissions } = useUser();
  const permissions = getUserPermissions();
  const [value, setValue] = useState(permissions.includes(PARAMETERS_VIEW_PERMISSION) ? 'Parameters' : 'Users');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container fluid>
        <TabContext value={value}>
          <Box sx={{ borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="Settings Tabs">
              {permissions.includes(PARAMETERS_VIEW_PERMISSION) && <Tab label="Parameters" value="Parameters" />}
              {permissions.includes(USERS_VIEW_PERMISSION) && <Tab label="Users" value="Users" />}
            </TabList>
          </Box>
          {permissions.includes(PARAMETERS_VIEW_PERMISSION) && <TabPanel value="Parameters" className='tab-parameters'><Parameters /></TabPanel>}
          {permissions.includes(USERS_VIEW_PERMISSION) && <TabPanel value="Users" className='tab-users'><Users /></TabPanel>}
        </TabContext>
      </Container>
    </>
  );
}

export default Settings;
