import { HttpService } from "./http.service";

const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_GATEWAY_REST_ID = process.env.REACT_APP_AWS_GATEWAY_REST_ID;
const ENV = process.env.REACT_APP_ENV;

export default class AuthService {

    httpService = new HttpService();
    url = `https://${AWS_GATEWAY_REST_ID}.execute-api.${AWS_REGION}.amazonaws.com/${ENV}/auth`;
    async login(username, password) {
        const body = {
            username: username,
            password: password
        };
        const payload = {
            type: 'SIGN_IN',
            body: JSON.stringify(body)
        };
        return this.httpService.post(this.url, payload);
    }

    async signout(accessToken) {
        const body = {
            accessToken: accessToken
        };

        const payload = {
            type: 'SIGN_OUT',
            body: JSON.stringify(body)
        };
        return this.httpService.post(this.url, payload);
    }

}