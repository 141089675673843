import { Button, Card, CardContent, InputAdornment, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Iconify from "components/iconify/Iconify";
import { useState } from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import "./Help.css";
const helpSections = [
    {
        content: "Overview of the solution",
        path: '/admin/overview-solution'
    },
    {
        content: "Live Map",
        path: '/admin/live-map'
    },
    {
        content: "Jobs Management",
        path: "/admin/jobs-management"
    },
    {
        content: "Vehicle Section",
        path: "/admin/vehicle-management"
    },
    {
        content: "Twistlocks",
        path: "/admin/twistlocks-help"
    },
    {
        content: "Alarms and notifications",
        path: "/admin/alarms-notifications"
    },
    {
        content: "System configuration",
        path: "/admin/system-configuration"
    },
    {
        content: "User management",
        path: '/admin/user-management'
    },
];

const SearchBar = ({ onChange }) => {
    return (
        <Container maxWidth="md" sx={{ mt: 1 }}>
            <TextField
                id="search"
                type="search"
                label="Search"
                variant="filled"
                className="search-input"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start" className="input-search-icon">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    ),
                }}
                onChange={onChange}
            />
        </Container>
    );
}
export default function Help() {
    const pdfURI = "https://hop-help-documentation.s3.eu-central-1.amazonaws.com/Avms.user.guide.v24.0532.v0.2.pdf"
    const [sections, setSections] = useState(helpSections);
    const history = useHistory();
    const onCardClick = (path) => {
        if (!!path) {
            history.push(path);
        }
    };

    const onSearch = (event) => {
        const query = event.target.value;
        const filterSections = helpSections.filter(section => section.content.toLowerCase().indexOf(query.toLowerCase()) !== -1);
        setSections(filterSections);
    };

    const downloadDocumentation = () => {

        fetch(pdfURI)
            .then(response => response.blob())
            .then(blob => {
                var downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = 'Avms.user.guide.v24.0532.v0.2.pdf';
                downloadLink.type = 'application/pdf';
                downloadLink.crossOrigin = "*"
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(downloadLink.href);
            })
            .catch(error => console.error('Error downling PDF file', error));

    }

    return <>
        <div className="app__help-search">
            <SearchBar onChange={onSearch} />
        </div>
        <div className="app__help-content">
            <div className="content-header">
                <h4>Help Articles</h4><hr />
            </div>
            <div className="row content-container">
                {sections.map(section =>
                    <div key={section.content} className="card-container col-sm-6 col-md-4 col-lg-4">
                        <Card className="card-content" onClick={() => onCardClick(section.path)}>
                            <CardContent >
                                <Typography component="div" variant="h5" className="typography">
                                    {section.content}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </div>
            <div className="help-actions-container">
                <Button className="download-btn" variant="contained" color='info' onClick={() => downloadDocumentation()} >
                    Download PDF
                </Button>
            </div>
        </div>
    </>
}