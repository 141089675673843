import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, OutlinedInput, InputAdornment, Tooltip, IconButton, Typography } from '@mui/material';
import Iconify from 'components/iconify/Iconify';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 350,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

Search.propTypes = {
    numSelected: PropTypes.number,
    filteredQuery: PropTypes.string,
    placeholder: PropTypes.string,
    isDeleteButtonVisible: PropTypes.bool,
    isCloseButtonVisible: PropTypes.bool,
    isResetButtonVisible: PropTypes.bool,
    onFilterChange: PropTypes.func,
};

export default function Search({ filteredQuery, placeholder, onFilterChange, numSelected,
    isDeleteButtonVisible, isCloseButtonVisible, isResetButtonVisible }) {
    return (
        <StyledRoot
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <StyledSearch
                    value={filteredQuery}
                    onChange={onFilterChange}
                    placeholder={placeholder}
                    startAdornment={
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    }
                />
            )}

            {numSelected > 0 && (
                <div>
                    {isDeleteButtonVisible && <Tooltip title="Delete">
                        <IconButton>
                            <Iconify icon="eva:trash-2-fill" />
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title="Map">
                        <IconButton>
                            <Iconify icon="eva:map-fill" />
                        </IconButton>
                    </Tooltip>
                    {isCloseButtonVisible && <Tooltip title="Close">
                        <IconButton>
                            <Iconify icon="eva:close-circle-fill" />
                        </IconButton>
                    </Tooltip>}
                    {isResetButtonVisible && <Tooltip title="Reset">
                        <IconButton>
                            <Iconify icon="eva:refresh-fill" />
                        </IconButton>
                    </Tooltip>}
                </div>
            )}
        </StyledRoot>
    );
}

