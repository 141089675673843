import "./HelpContent.css";
export default function LiveMapPage() {
    return (
        <div className="help-content-container">
            <p>The live map provides real-time visualization of truck locations and job progress within the Terminal</p>
            <p><img src={require("assets/img/liveMap.png")} alt="Live Map page" style={{ width: '80%' }} /></p>
            <h5> <b>1. Key features</b></h5>
            <p>
                <b>Zoom Functionality:</b> The "Live Map" allows users to zoom in and out to focus on specific areas of interest. To zoom in, simply use the zoom-in button or pinch gestures on touch devices. To zoom out, use the zoom-out button or perform the reverse pinch gesture.
            </p>
            <p>
                <b>Truck Location Indicator: </b>Trucks are now represented as small red trucks on the map, allowing you to easily track their real-time positions within the terminal.
            </p>
            <p>
                <b>Roadway Visualization:</b> Within the terminal, the roadways that trucks can travel on are displayed in a distinct black color. This clear representation helps to understand the routes available for truck movement.
            </p>
            <p>
                <b>Positions on the Live Map:</b> Small black dots on the map indicate specific positions within the terminal. When you click on these dots, you can see the position name. These markers serve to distinguish each position.
            </p>
            <p><img src={require("assets/img/mapStation.png")} alt="Map station tooltip" /></p>
            <h5><b>2. Exploring the positions</b></h5>
            <p>Some of the most common types of positions :</p>
            <p><b>Transfer points:</b> These are the positions where containers are transferred between different modes of transportation.</p>
            <p><b>Twistlock application points:</b> These are the positions where twistlocks are applied to containers to secure them for transport</p>
        </div>
    );
}