import { Icon } from 'leaflet';
import "leaflet-rotatedmarker";
import { forwardRef, useEffect, useRef, useState } from "react";
import { CircleMarker, MapContainer, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import SettingService from 'services/setting.service';
import ws from '../../services/webSocketConfig';
import vehicleIcon from './../../assets/icons/vehicle-icon.png';
import { siteData } from './../../data';
import { convertRadionToDegree } from './../../utils/utilFunctions';
import './Maps.scss';

ws.onopen = (event) => {
  console.log("connection established !");
  return true;
};

const mapCenter = [
  56.0293780191,
  12.6947987024
]
const defaultZoom = 15;

const icon = new Icon({
  iconUrl: vehicleIcon,
  iconSize: [20, 8],
});

const blackOptions = { color: 'black' }

const RotatedMarker = forwardRef(({ children, ...props }, forwardRef) => {
  const markerRef = useRef();
  const { rotationAngle, rotationOrigin, position } = props;
  console.log("current pos :", position);
  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.setRotationAngle(rotationAngle).update();
      marker.setRotationOrigin(rotationOrigin);
      marker.setLatLng(position).update();
    }
  }, [rotationAngle, rotationOrigin, position]);

  return (
    <Marker
      key={`${rotationAngle}-angle`}
      ref={(ref) => {
        markerRef.current = ref;
        if (forwardRef) {
          forwardRef.current = ref;
        }
      }}
      {...props}
      rotationAngle={rotationAngle}
    >
      {children}
    </Marker>
  );
});

const settingService = new SettingService();

function Maps() {
  const [activeVehicle, setActiveVehicle] = useState(null);
  const [mapCenterLat, setMapCenterLat] = useState(mapCenter[0]);
  const [mapCenterLon, setMapCenterLon] = useState(mapCenter[1]);
  const [mapZoom, setMapZoom] = useState(defaultZoom);

  const [vehicleData, setVehicleData] = useState([]);
  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (!data?.type) {
      console.log("received data : ", data);
      const index = vehicleData.findIndex(vehicle => vehicle.id === data.vehicleId);
      if (index === -1) {
        const vehicle = {
          id: data.vehicleId,
          tosId: data.tosId,
          position: {
            lon: data.lon,
            lat: data.lat
          },
          theta: data.theta
        }
        setVehicleData([...vehicleData, vehicle])

      } else {
        const arr = [...vehicleData];
        arr[index] = {
          id: data.vehicleId,
          position: {
            lon: data.lon,
            lat: data.lat
          },
          theta: data.theta
        };
        setVehicleData([...arr]);
      }
      setActiveVehicle(null);
      return true;
    }
  };

  useEffect(() => {
    settingService.fetchAll().then(data => {
      const centerLat = data.find(parameter => parameter.id === 'ux_map_center_lat');
      const centerLon = data.find(parameter => parameter.id === 'ux_map_center_lon');
      const mapZoom = data.find(parameter => parameter.id === 'ux_map_zoom');

      setMapCenterLat(!!centerLat?.value ? Number(centerLat?.value) : mapCenter[0]);
      setMapCenterLon(!!centerLon?.value ? Number(centerLon?.value) : mapCenter[1]);
      setMapZoom(!!mapZoom?.value ? Number(mapZoom?.value) : defaultZoom);
    });
  }, []);

  return (
    <>
      <div className="leaflet-container">
        <MapContainer className='map' MapContainer center={[mapCenterLat, mapCenterLon]} zoom={mapZoom} scrollWheelZoom={false}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {
            vehicleData.map((vehicle) => {
              console.log("current position : ", vehicle.position);

              return <RotatedMarker
                key={vehicle.id} position={vehicle.position}
                rotationAngle={convertRadionToDegree(vehicle.theta)}
                rotationOrigin='center'
                eventHandlers={{
                  click: (e) => {
                    setActiveVehicle(vehicle);
                  },
                }}
                icon={icon}
              />
            })
          }
          {
            siteData.edges.features.map((feature, index) => {
              const coordinates = feature.geometry.coordinates.map(pos => [...pos].reverse());
              return <Polyline key={index} pathOptions={blackOptions} positions={coordinates} />
            })
          }
          {
            siteData.stations.features.map(station => {
              const circleCenter = station.geometry.coordinates.reverse();
              return <CircleMarker key={station.properties.name} center={circleCenter} pathOptions={blackOptions} radius={3}>
                <Popup>
                  <p>{station.properties.name}</p>
                </Popup>
              </CircleMarker>
            })
          }
          {
            activeVehicle && (
              <Popup position={activeVehicle.position} onClose={() => setActiveVehicle(null)} >
                <div>
                  <h5>{`vehicle ${activeVehicle.tosId}`}</h5>
                </div>
              </Popup>
            )
          }
        </MapContainer>
      </div>
    </>
  );
}

export default Maps;
