import { HttpService } from './http.service';

const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_GATEWAY_REST_ID = process.env.REACT_APP_AWS_GATEWAY_REST_ID;
const ENV = process.env.REACT_APP_ENV;

export default class JobsService {
    httpService = new HttpService();
    url = `https://${AWS_GATEWAY_REST_ID}.execute-api.${AWS_REGION}.amazonaws.com/${ENV}/jobs`;
    async fetchAll() {
        return this.httpService.get(this.url);
    }

    async put(body) {
        return this.httpService.put(this.url, body);
    }

    async post(body) {
        return this.httpService.post(this.url, body);
    }

    async delete(jobId) {
        const deleteUrl = `${this.url}/${jobId}`;
        return this.httpService.delele(deleteUrl);
    }
}