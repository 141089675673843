const site = {
    unhook_points: {
        type: "FeatureCollection",
        features: []
    },
    traffic_lights: {
        type: "FeatureCollection",
        features: []
    },
    taxiways: {
        type: "FeatureCollection",
        features: []
    },
    stations: {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: {
                    name: "906A+906B+906C",
                    id: 9
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6964140509,
                        56.0297968028
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "906D+906E",
                    id: 10
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6962963848,
                        56.0296235097
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "906F+906G",
                    id: 11
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6961456725,
                        56.0294015452
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "906H+906I",
                    id: 0
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6959650052,
                        56.0291354378
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "906J+906K",
                    id: 1
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6958281173,
                        56.0289135621
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "906L+906M+906N",
                    id: 2
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6956471122,
                        56.028649345
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "GRD+GRE",
                    id: 17
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6924321563,
                        56.0313476296
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "STS stop",
                    id: 15
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6948943827,
                        56.0296681361
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "VIA+VIB+VIC",
                    id: 4
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6982664696,
                        56.029458264
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "VID+VIE",
                    id: 5
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6981060598,
                        56.0292894845
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "VIF+VIG",
                    id: 6
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6979606426,
                        56.0291199983
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "VIH+VII",
                    id: 7
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6978148571,
                        56.028948352
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    name: "VIJ+VIK",
                    id: 14
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6976620858,
                        56.02877949
                    ]
                }
            }
        ]
    },
    site_name: "tug-hhab",
    intersections: {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: -0.5473,
                    lock_type: "observation",
                    id: 3,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.69837929,
                        56.0297930897
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: 0.5828,
                    lock_type: "observation",
                    id: 8,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6956769144,
                        56.0303601086
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: 2.6725,
                    lock_type: "observation",
                    id: 12,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6962658644,
                        56.0290368475
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: -2.1314,
                    lock_type: "observation",
                    id: 13,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6976269845,
                        56.0287451661
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: 0.55,
                    lock_type: "observation",
                    id: 16,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6952736324,
                        56.0300915227
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: 1.3996,
                    lock_type: "observation",
                    id: 18,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6955920951,
                        56.0303410976
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: 2.4598,
                    lock_type: "observation",
                    id: 19,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6927155055,
                        56.0308147715
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: -1.201,
                    lock_type: "observation",
                    id: 20,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6923952533,
                        56.03086171
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "yield",
                    theta: -0.0533,
                    lock_type: "observation",
                    id: 21,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6935177579,
                        56.0306489334
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "stop",
                    theta: 2.8126,
                    lock_type: "observation",
                    id: 22,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6944498127,
                        56.028536136
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    type: "stop",
                    theta: 2.2405,
                    lock_type: "observation",
                    id: 23,
                    deactivate_slow_startup: false
                },
                geometry: {
                    type: "Point",
                    coordinates: [
                        12.6946219448,
                        56.0285417619
                    ]
                }
            }
        ]
    },
    id: "929ad802505b2803cfd4312a6146c5e61f613ba5",
    hook_points: {
        type: "FeatureCollection",
        features: []
    },
    gps_origin_lon: 12.69612548,
    gps_origin_lat: 56.02919575,
    edges: {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: {
                    length: 40.7639015853,
                    id: 0
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6950939129,
                            56.0299774562
                        ],
                        [
                            12.695103293,
                            56.0299915033
                        ],
                        [
                            12.695114666,
                            56.0300059517
                        ],
                        [
                            12.695128939,
                            56.0300203102
                        ],
                        [
                            12.6951466207,
                            56.0300342453
                        ],
                        [
                            12.6951678713,
                            56.0300475614
                        ],
                        [
                            12.6951925524,
                            56.0300601809
                        ],
                        [
                            12.6952202765,
                            56.030072125
                        ],
                        [
                            12.695250457,
                            56.0300834936
                        ],
                        [
                            12.695282358,
                            56.0300944458
                        ],
                        [
                            12.695315144,
                            56.0301051798
                        ],
                        [
                            12.6953479297,
                            56.0301159139
                        ],
                        [
                            12.6953798299,
                            56.0301268662
                        ],
                        [
                            12.695410009,
                            56.0301382351
                        ],
                        [
                            12.6954377313,
                            56.0301501795
                        ],
                        [
                            12.6954624101,
                            56.0301627995
                        ],
                        [
                            12.6954836581,
                            56.0301761161
                        ],
                        [
                            12.6955013369,
                            56.0301900517
                        ],
                        [
                            12.6955156068,
                            56.0302044109
                        ],
                        [
                            12.6955269767,
                            56.0302188599
                        ],
                        [
                            12.6955363538,
                            56.0302329076
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 34.7562444454,
                    id: 2
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.694457391,
                            56.0285347144
                        ],
                        [
                            12.6944282507,
                            56.0285402838
                        ],
                        [
                            12.6944007053,
                            56.0285461655
                        ],
                        [
                            12.6943752339,
                            56.0285527442
                        ],
                        [
                            12.6943522062,
                            56.0285602897
                        ],
                        [
                            12.6943318904,
                            56.0285689695
                        ],
                        [
                            12.6943144622,
                            56.0285788625
                        ],
                        [
                            12.6943000136,
                            56.0285899713
                        ],
                        [
                            12.6942885616,
                            56.0286022358
                        ],
                        [
                            12.6942800569,
                            56.0286155457
                        ],
                        [
                            12.6942743927,
                            56.0286297542
                        ],
                        [
                            12.6942714133,
                            56.0286446902
                        ],
                        [
                            12.6942709229,
                            56.028660172
                        ],
                        [
                            12.6942726945,
                            56.0286760199
                        ],
                        [
                            12.6942764783,
                            56.0286920696
                        ],
                        [
                            12.6942820108,
                            56.0287081847
                        ],
                        [
                            12.6942890232,
                            56.0287242703
                        ],
                        [
                            12.6942972504,
                            56.0287402856
                        ],
                        [
                            12.6943064395,
                            56.028756257
                        ],
                        [
                            12.6943163587,
                            56.0287722913
                        ],
                        [
                            12.694326806,
                            56.0287885886
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 28.3975894147,
                    id: 3
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6948888512,
                            56.0284529896
                        ],
                        [
                            12.6948706053,
                            56.0284564087
                        ],
                        [
                            12.6948512058,
                            56.0284600496
                        ],
                        [
                            12.6948308381,
                            56.0284638814
                        ],
                        [
                            12.6948096673,
                            56.0284678756
                        ],
                        [
                            12.6947878409,
                            56.0284720053
                        ],
                        [
                            12.6947654915,
                            56.0284762457
                        ],
                        [
                            12.6947427386,
                            56.0284805728
                        ],
                        [
                            12.6947196921,
                            56.0284849641
                        ],
                        [
                            12.694696454,
                            56.0284893977
                        ],
                        [
                            12.6946731214,
                            56.0284938523
                        ],
                        [
                            12.6946497887,
                            56.0284983069
                        ],
                        [
                            12.6946265506,
                            56.0285027404
                        ],
                        [
                            12.6946035041,
                            56.0285071317
                        ],
                        [
                            12.6945807512,
                            56.0285114588
                        ],
                        [
                            12.6945584017,
                            56.028515699
                        ],
                        [
                            12.6945365753,
                            56.0285198288
                        ],
                        [
                            12.6945154044,
                            56.0285238228
                        ],
                        [
                            12.6944950366,
                            56.0285276546
                        ],
                        [
                            12.694475637,
                            56.0285312954
                        ],
                        [
                            12.694457391,
                            56.0285347144
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 30.9239329931,
                    id: 4
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6959774609,
                            56.0291538052
                        ],
                        [
                            12.6959699368,
                            56.0291427183
                        ],
                        [
                            12.6959650052,
                            56.0291354378
                        ],
                        [
                            12.6959619843,
                            56.0291309692
                        ],
                        [
                            12.6959536937,
                            56.0291186603
                        ],
                        [
                            12.6959451463,
                            56.0291058829
                        ],
                        [
                            12.6959364151,
                            56.0290927185
                        ],
                        [
                            12.6959275654,
                            56.0290792397
                        ],
                        [
                            12.695918656,
                            56.0290655128
                        ],
                        [
                            12.6959097392,
                            56.0290515982
                        ],
                        [
                            12.695900862,
                            56.0290375527
                        ],
                        [
                            12.6958920666,
                            56.0290234308
                        ],
                        [
                            12.6958833913,
                            56.0290092858
                        ],
                        [
                            12.6958748709,
                            56.0289951719
                        ],
                        [
                            12.6958665375,
                            56.0289811455
                        ],
                        [
                            12.6958584215,
                            56.0289672663
                        ],
                        [
                            12.6958505519,
                            56.0289535997
                        ],
                        [
                            12.6958429572,
                            56.0289402172
                        ],
                        [
                            12.6958356661,
                            56.028927199
                        ],
                        [
                            12.6958287081,
                            56.0289146345
                        ],
                        [
                            12.6958281173,
                            56.0289135621
                        ],
                        [
                            12.6958221144,
                            56.0289026248
                        ],
                        [
                            12.6958159183,
                            56.0288912833
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 75.0284165767,
                    id: 5
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6972931487,
                            56.0287453319
                        ],
                        [
                            12.6972478041,
                            56.0287581997
                        ],
                        [
                            12.6971995851,
                            56.0287718832
                        ],
                        [
                            12.697148947,
                            56.0287862532
                        ],
                        [
                            12.6970962972,
                            56.028801194
                        ],
                        [
                            12.6970420012,
                            56.028816602
                        ],
                        [
                            12.6969863882,
                            56.0288323836
                        ],
                        [
                            12.6969297577,
                            56.0288484539
                        ],
                        [
                            12.6968723851,
                            56.0288647348
                        ],
                        [
                            12.6968145276,
                            56.0288811533
                        ],
                        [
                            12.6967564307,
                            56.0288976396
                        ],
                        [
                            12.6966983338,
                            56.0289141259
                        ],
                        [
                            12.6966404762,
                            56.0289305443
                        ],
                        [
                            12.6965831033,
                            56.028946825
                        ],
                        [
                            12.6965264725,
                            56.0289628952
                        ],
                        [
                            12.6964708591,
                            56.0289786766
                        ],
                        [
                            12.6964165625,
                            56.0289940843
                        ],
                        [
                            12.6963639122,
                            56.0290090248
                        ],
                        [
                            12.6963132735,
                            56.0290233944
                        ],
                        [
                            12.6962650538,
                            56.0290370775
                        ],
                        [
                            12.6962197086,
                            56.029049945
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 177.5134865486,
                    id: 6
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.694576876,
                            56.0287318557
                        ],
                        [
                            12.6946176994,
                            56.028795205
                        ],
                        [
                            12.6946610666,
                            56.028862579
                        ],
                        [
                            12.694706536,
                            56.0289333477
                        ],
                        [
                            12.6947537222,
                            56.0290069458
                        ],
                        [
                            12.6948022887,
                            56.0290828643
                        ],
                        [
                            12.6948519412,
                            56.0291606426
                        ],
                        [
                            12.6949024204,
                            56.0292398604
                        ],
                        [
                            12.6949534953,
                            56.0293201296
                        ],
                        [
                            12.6950049557,
                            56.0294010863
                        ],
                        [
                            12.6950566054,
                            56.0294823827
                        ],
                        [
                            12.6951082553,
                            56.029563679
                        ],
                        [
                            12.6951597163,
                            56.0296446357
                        ],
                        [
                            12.6952107923,
                            56.0297249047
                        ],
                        [
                            12.6952612731,
                            56.0298041224
                        ],
                        [
                            12.6953109275,
                            56.0298819005
                        ],
                        [
                            12.6953594963,
                            56.0299578187
                        ],
                        [
                            12.695406685,
                            56.0300314166
                        ],
                        [
                            12.6954521572,
                            56.030102185
                        ],
                        [
                            12.6954955273,
                            56.0301695586
                        ],
                        [
                            12.6955363538,
                            56.0302329076
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 34.1030255598,
                    id: 7
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6958159183,
                            56.0288912833
                        ],
                        [
                            12.6958088133,
                            56.0288782754
                        ],
                        [
                            12.6958013551,
                            56.0288646831
                        ],
                        [
                            12.6957935603,
                            56.0288506152
                        ],
                        [
                            12.6957854328,
                            56.0288361696
                        ],
                        [
                            12.6957769658,
                            56.028821435
                        ],
                        [
                            12.6957681452,
                            56.0288064917
                        ],
                        [
                            12.6957589519,
                            56.0287914128
                        ],
                        [
                            12.6957493642,
                            56.0287762653
                        ],
                        [
                            12.695739361,
                            56.0287611113
                        ],
                        [
                            12.6957289241,
                            56.0287460089
                        ],
                        [
                            12.6957180411,
                            56.0287310137
                        ],
                        [
                            12.6957067078,
                            56.0287161797
                        ],
                        [
                            12.6956949312,
                            56.0287015605
                        ],
                        [
                            12.6956827317,
                            56.0286872103
                        ],
                        [
                            12.6956701463,
                            56.0286731852
                        ],
                        [
                            12.6956572308,
                            56.0286595441
                        ],
                        [
                            12.6956471122,
                            56.028649345
                        ],
                        [
                            12.6956440629,
                            56.0286463503
                        ],
                        [
                            12.6956307445,
                            56.0286336719
                        ],
                        [
                            12.6956174044,
                            56.0286215836
                        ],
                        [
                            12.6956042012,
                            56.0286101676
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 28.2197777844,
                    id: 8
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6983048389,
                            56.0298143628
                        ],
                        [
                            12.6983278874,
                            56.0298082858
                        ],
                        [
                            12.6983500798,
                            56.0298021375
                        ],
                        [
                            12.6983713786,
                            56.0297956833
                        ],
                        [
                            12.6983916825,
                            56.0297887561
                        ],
                        [
                            12.6984108389,
                            56.0297812489
                        ],
                        [
                            12.698428656,
                            56.0297731066
                        ],
                        [
                            12.6984449153,
                            56.0297643189
                        ],
                        [
                            12.6984593838,
                            56.029754912
                        ],
                        [
                            12.6984718265,
                            56.0297449408
                        ],
                        [
                            12.6984820187,
                            56.0297344818
                        ],
                        [
                            12.6984897583,
                            56.0297236246
                        ],
                        [
                            12.698494878,
                            56.0297124647
                        ],
                        [
                            12.698497258,
                            56.0297010953
                        ],
                        [
                            12.698496838,
                            56.0296895999
                        ],
                        [
                            12.6984936298,
                            56.0296780445
                        ],
                        [
                            12.6984877295,
                            56.0296664695
                        ],
                        [
                            12.6984793297,
                            56.0296548826
                        ],
                        [
                            12.6984687323,
                            56.0296432504
                        ],
                        [
                            12.6984563602,
                            56.0296314909
                        ],
                        [
                            12.6984427703,
                            56.029619466
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 105.148245352,
                    id: 9
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6984427703,
                            56.029619466
                        ],
                        [
                            12.6984038833,
                            56.0295853581
                        ],
                        [
                            12.6983632501,
                            56.0295491123
                        ],
                        [
                            12.6983216208,
                            56.0295109288
                        ],
                        [
                            12.6982795746,
                            56.0294710126
                        ],
                        [
                            12.6982664696,
                            56.029458264
                        ],
                        [
                            12.6982375397,
                            56.0294295738
                        ],
                        [
                            12.6981958125,
                            56.0293868273
                        ],
                        [
                            12.6981545775,
                            56.0293429926
                        ],
                        [
                            12.6981139269,
                            56.0292982941
                        ],
                        [
                            12.6981060598,
                            56.0292894845
                        ],
                        [
                            12.6980738799,
                            56.0292529609
                        ],
                        [
                            12.6980344028,
                            56.0292072266
                        ],
                        [
                            12.6979954283,
                            56.0291613293
                        ],
                        [
                            12.6979606426,
                            56.0291199983
                        ],
                        [
                            12.6979568751,
                            56.0291155119
                        ],
                        [
                            12.6979186678,
                            56.0290700215
                        ],
                        [
                            12.6978807561,
                            56.0290251097
                        ],
                        [
                            12.6978431346,
                            56.0289810325
                        ],
                        [
                            12.6978148571,
                            56.028948352
                        ],
                        [
                            12.6978058626,
                            56.02893805
                        ],
                        [
                            12.6977690834,
                            56.0288964267
                        ],
                        [
                            12.6977330441,
                            56.0288564314
                        ],
                        [
                            12.6976981152,
                            56.0288183368
                        ],
                        [
                            12.6976648101,
                            56.0287824197
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 68.7441646377,
                    id: 11
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6955363538,
                            56.0302329076
                        ],
                        [
                            12.69555595,
                            56.0302624941
                        ],
                        [
                            12.6955771421,
                            56.0302900943
                        ],
                        [
                            12.6956016532,
                            56.030315015
                        ],
                        [
                            12.6956306328,
                            56.030336755
                        ],
                        [
                            12.6956647244,
                            56.0303549862
                        ],
                        [
                            12.6957041333,
                            56.0303695347
                        ],
                        [
                            12.6957486945,
                            56.0303803627
                        ],
                        [
                            12.6957979404,
                            56.0303875488
                        ],
                        [
                            12.695851169,
                            56.0303912701
                        ],
                        [
                            12.6959075112,
                            56.0303917829
                        ],
                        [
                            12.6959659993,
                            56.0303894045
                        ],
                        [
                            12.6960256341,
                            56.0303844937
                        ],
                        [
                            12.6960854534,
                            56.0303774328
                        ],
                        [
                            12.6961445996,
                            56.0303686084
                        ],
                        [
                            12.6962023871,
                            56.0303583928
                        ],
                        [
                            12.6962583711,
                            56.0303471254
                        ],
                        [
                            12.6963124146,
                            56.0303350935
                        ],
                        [
                            12.6963647564,
                            56.0303225141
                        ],
                        [
                            12.6964160794,
                            56.0303095148
                        ],
                        [
                            12.6964675777,
                            56.0302961151
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 99.8506658552,
                    id: 14
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6965466282,
                            56.0299920544
                        ],
                        [
                            12.6965181692,
                            56.029950142
                        ],
                        [
                            12.6964897104,
                            56.0299082296
                        ],
                        [
                            12.6964612515,
                            56.0298663172
                        ],
                        [
                            12.6964327928,
                            56.0298244048
                        ],
                        [
                            12.6964140509,
                            56.0297968028
                        ],
                        [
                            12.6964043341,
                            56.0297824924
                        ],
                        [
                            12.6963758754,
                            56.0297405799
                        ],
                        [
                            12.6963474168,
                            56.0296986675
                        ],
                        [
                            12.6963189583,
                            56.0296567551
                        ],
                        [
                            12.6962963848,
                            56.0296235097
                        ],
                        [
                            12.6962904999,
                            56.0296148426
                        ],
                        [
                            12.6962620415,
                            56.0295729302
                        ],
                        [
                            12.6962335831,
                            56.0295310177
                        ],
                        [
                            12.6962051249,
                            56.0294891052
                        ],
                        [
                            12.6961766666,
                            56.0294471927
                        ],
                        [
                            12.6961482085,
                            56.0294052803
                        ],
                        [
                            12.6961456725,
                            56.0294015452
                        ],
                        [
                            12.6961197504,
                            56.0293633678
                        ],
                        [
                            12.6960912924,
                            56.0293214553
                        ],
                        [
                            12.6960628344,
                            56.0292795428
                        ],
                        [
                            12.6960343765,
                            56.0292376302
                        ],
                        [
                            12.6960059187,
                            56.0291957177
                        ],
                        [
                            12.6959774609,
                            56.0291538052
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 37.6360402202,
                    id: 15
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6962197086,
                            56.029049945
                        ],
                        [
                            12.6961893696,
                            56.0290584148
                        ],
                        [
                            12.6961600428,
                            56.0290658288
                        ],
                        [
                            12.6961316032,
                            56.0290716314
                        ],
                        [
                            12.6961039916,
                            56.0290754244
                        ],
                        [
                            12.6960772031,
                            56.0290769504
                        ],
                        [
                            12.6960512772,
                            56.0290760762
                        ],
                        [
                            12.696026287,
                            56.0290727764
                        ],
                        [
                            12.6960023289,
                            56.0290671168
                        ],
                        [
                            12.6959795116,
                            56.0290592378
                        ],
                        [
                            12.6959579461,
                            56.0290493381
                        ],
                        [
                            12.6959377348,
                            56.0290376577
                        ],
                        [
                            12.6959189611,
                            56.0290244616
                        ],
                        [
                            12.6959016789,
                            56.0290100234
                        ],
                        [
                            12.6958859019,
                            56.0289946085
                        ],
                        [
                            12.6958715934,
                            56.0289784577
                        ],
                        [
                            12.6958586553,
                            56.0289617707
                        ],
                        [
                            12.6958469181,
                            56.0289446892
                        ],
                        [
                            12.6958361298,
                            56.0289272809
                        ],
                        [
                            12.6958259459,
                            56.0289095225
                        ],
                        [
                            12.6958159183,
                            56.0288912833
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 51.5925385629,
                    id: 16
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6956042012,
                            56.0286101676
                        ],
                        [
                            12.695583373,
                            56.0285932737
                        ],
                        [
                            12.6955609031,
                            56.0285768364
                        ],
                        [
                            12.6955360244,
                            56.0285607162
                        ],
                        [
                            12.6955082731,
                            56.0285448761
                        ],
                        [
                            12.6954774515,
                            56.0285293657
                        ],
                        [
                            12.6954435898,
                            56.0285143058
                        ],
                        [
                            12.6954069091,
                            56.0284998725
                        ],
                        [
                            12.6953677836,
                            56.0284862814
                        ],
                        [
                            12.6953267032,
                            56.0284737723
                        ],
                        [
                            12.6952842358,
                            56.028462593
                        ],
                        [
                            12.6952409898,
                            56.0284529841
                        ],
                        [
                            12.6951975765,
                            56.028445163
                        ],
                        [
                            12.6951545728,
                            56.0284393083
                        ],
                        [
                            12.6951124832,
                            56.0284355441
                        ],
                        [
                            12.6950717028,
                            56.0284339243
                        ],
                        [
                            12.6950324792,
                            56.028434417
                        ],
                        [
                            12.6949948753,
                            56.0284368887
                        ],
                        [
                            12.6949587319,
                            56.0284410886
                        ],
                        [
                            12.6949236294,
                            56.028446633
                        ],
                        [
                            12.6948888512,
                            56.0284529896
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 126.4802760092,
                    id: 17
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6964675777,
                            56.0302961151
                        ],
                        [
                            12.6965452324,
                            56.0302758168
                        ],
                        [
                            12.6966278032,
                            56.0302542239
                        ],
                        [
                            12.6967145054,
                            56.0302315348
                        ],
                        [
                            12.6968046386,
                            56.0302079286
                        ],
                        [
                            12.6968975761,
                            56.0301835674
                        ],
                        [
                            12.696992754,
                            56.0301585992
                        ],
                        [
                            12.6970896612,
                            56.0301331598
                        ],
                        [
                            12.6971878285,
                            56.0301073753
                        ],
                        [
                            12.6972868183,
                            56.0300813647
                        ],
                        [
                            12.697386214,
                            56.0300552423
                        ],
                        [
                            12.6974856096,
                            56.0300291199
                        ],
                        [
                            12.697584599,
                            56.0300031091
                        ],
                        [
                            12.6976827656,
                            56.0299773242
                        ],
                        [
                            12.6977796719,
                            56.0299518842
                        ],
                        [
                            12.6978748487,
                            56.0299269153
                        ],
                        [
                            12.6979677848,
                            56.0299025534
                        ],
                        [
                            12.6980579166,
                            56.0298789463
                        ],
                        [
                            12.6981446171,
                            56.0298562562
                        ],
                        [
                            12.698227186,
                            56.0298346622
                        ],
                        [
                            12.6983048389,
                            56.0298143628
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 38.5821899059,
                    id: 19
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6964675777,
                            56.0302961151
                        ],
                        [
                            12.6964941538,
                            56.0302889971
                        ],
                        [
                            12.6965191404,
                            56.030281352
                        ],
                        [
                            12.6965419175,
                            56.0302726329
                        ],
                        [
                            12.6965620298,
                            56.0302624799
                        ],
                        [
                            12.6965791713,
                            56.0302506983
                        ],
                        [
                            12.6965931697,
                            56.0302372363
                        ],
                        [
                            12.6966039704,
                            56.0302221623
                        ],
                        [
                            12.6966116214,
                            56.030205643
                        ],
                        [
                            12.6966162575,
                            56.0301879212
                        ],
                        [
                            12.6966180842,
                            56.0301692929
                        ],
                        [
                            12.6966173627,
                            56.0301500859
                        ],
                        [
                            12.6966143941,
                            56.0301306368
                        ],
                        [
                            12.6966095036,
                            56.0301112688
                        ],
                        [
                            12.6966030248,
                            56.03009227
                        ],
                        [
                            12.6965952845,
                            56.0300738703
                        ],
                        [
                            12.6965865866,
                            56.0300562196
                        ],
                        [
                            12.6965771967,
                            56.0300393655
                        ],
                        [
                            12.6965673265,
                            56.0300232309
                        ],
                        [
                            12.6965571181,
                            56.0300075917
                        ],
                        [
                            12.6965466282,
                            56.0299920544
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 28.1698468951,
                    id: 20
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6976648101,
                            56.0287824197
                        ],
                        [
                            12.6976620858,
                            56.02877949
                        ],
                        [
                            12.6976523501,
                            56.0287691551
                        ],
                        [
                            12.697639861,
                            56.0287566523
                        ],
                        [
                            12.6976269945,
                            56.0287451744
                        ],
                        [
                            12.6976134907,
                            56.0287349234
                        ],
                        [
                            12.6975991686,
                            56.0287260463
                        ],
                        [
                            12.6975839165,
                            56.0287186389
                        ],
                        [
                            12.6975676825,
                            56.0287127519
                        ],
                        [
                            12.6975504647,
                            56.028708395
                        ],
                        [
                            12.6975323019,
                            56.0287055425
                        ],
                        [
                            12.6975132641,
                            56.0287041378
                        ],
                        [
                            12.6974934425,
                            56.0287040987
                        ],
                        [
                            12.6974729403,
                            56.0287053224
                        ],
                        [
                            12.6974518631,
                            56.0287076902
                        ],
                        [
                            12.6974303091,
                            56.0287110727
                        ],
                        [
                            12.6974083597,
                            56.0287153348
                        ],
                        [
                            12.69738607,
                            56.0287203405
                        ],
                        [
                            12.697363459,
                            56.0287259581
                        ],
                        [
                            12.6973405003,
                            56.0287320649
                        ],
                        [
                            12.6973171123,
                            56.0287385526
                        ],
                        [
                            12.6972931487,
                            56.0287453319
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 140.7460753921,
                    id: 21
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.694326806,
                            56.0287885886
                        ],
                        [
                            12.6943591923,
                            56.0288388131
                        ],
                        [
                            12.6943936342,
                            56.0288922208
                        ],
                        [
                            12.6944298084,
                            56.028948307
                        ],
                        [
                            12.6944674251,
                            56.0290066201
                        ],
                        [
                            12.6945062237,
                            56.0290667554
                        ],
                        [
                            12.6945459689,
                            56.0291283481
                        ],
                        [
                            12.6945864462,
                            56.0291910667
                        ],
                        [
                            12.6946274581,
                            56.0292546064
                        ],
                        [
                            12.6946688193,
                            56.0293186824
                        ],
                        [
                            12.6947103532,
                            56.0293830231
                        ],
                        [
                            12.6947518872,
                            56.0294473639
                        ],
                        [
                            12.6947932489,
                            56.0295114398
                        ],
                        [
                            12.6948342615,
                            56.0295749794
                        ],
                        [
                            12.6948747398,
                            56.0296376979
                        ],
                        [
                            12.6948943827,
                            56.0296681361
                        ],
                        [
                            12.6949144863,
                            56.0296992904
                        ],
                        [
                            12.6949532865,
                            56.0297594255
                        ],
                        [
                            12.6949909048,
                            56.0298177385
                        ],
                        [
                            12.6950270809,
                            56.0298738244
                        ],
                        [
                            12.6950615248,
                            56.0299272319
                        ],
                        [
                            12.6950939129,
                            56.0299774562
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 42.301236408,
                    id: 22
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6948888512,
                            56.0284529896
                        ],
                        [
                            12.6948559953,
                            56.028459226
                        ],
                        [
                            12.6948238931,
                            56.0284657768
                        ],
                        [
                            12.6947923525,
                            56.0284730479
                        ],
                        [
                            12.6947613562,
                            56.028481327
                        ],
                        [
                            12.694731034,
                            56.0284907959
                        ],
                        [
                            12.6947016358,
                            56.0285015448
                        ],
                        [
                            12.6946735037,
                            56.028513585
                        ],
                        [
                            12.6946470448,
                            56.0285268624
                        ],
                        [
                            12.6946227036,
                            56.0285412708
                        ],
                        [
                            12.6946009346,
                            56.0285566653
                        ],
                        [
                            12.6945821748,
                            56.0285728754
                        ],
                        [
                            12.6945668164,
                            56.0285897185
                        ],
                        [
                            12.694555179,
                            56.0286070131
                        ],
                        [
                            12.6945474827,
                            56.028624592
                        ],
                        [
                            12.6945438198,
                            56.0286423161
                        ],
                        [
                            12.6945441282,
                            56.028660087
                        ],
                        [
                            12.6945481635,
                            56.0286778609
                        ],
                        [
                            12.6945554716,
                            56.0286956614
                        ],
                        [
                            12.694565361,
                            56.0287135933
                        ],
                        [
                            12.694576876,
                            56.0287318557
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 49.8219043555,
                    id: 23
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6955363538,
                            56.0302329076
                        ],
                        [
                            12.695551314,
                            56.0302563607
                        ],
                        [
                            12.6955650799,
                            56.0302793434
                        ],
                        [
                            12.6955770618,
                            56.0303020903
                        ],
                        [
                            12.6955867496,
                            56.0303247229
                        ],
                        [
                            12.6955937096,
                            56.0303472658
                        ],
                        [
                            12.6955975822,
                            56.030369663
                        ],
                        [
                            12.695598079,
                            56.0303917941
                        ],
                        [
                            12.6955949799,
                            56.0304134899
                        ],
                        [
                            12.6955881305,
                            56.0304345489
                        ],
                        [
                            12.6955774394,
                            56.0304547535
                        ],
                        [
                            12.6955628752,
                            56.0304738856
                        ],
                        [
                            12.6955444639,
                            56.0304917431
                        ],
                        [
                            12.6955222862,
                            56.0305081559
                        ],
                        [
                            12.6954964746,
                            56.0305230021
                        ],
                        [
                            12.6954672109,
                            56.0305362237
                        ],
                        [
                            12.6954347231,
                            56.0305478433
                        ],
                        [
                            12.6953992827,
                            56.0305579796
                        ],
                        [
                            12.6953612023,
                            56.0305668639
                        ],
                        [
                            12.6953208325,
                            56.0305748562
                        ],
                        [
                            12.6952785592,
                            56.0305824609
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 70.7332489004,
                    id: 24
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6952785592,
                            56.0305824609
                        ],
                        [
                            12.6952244635,
                            56.0305920256
                        ],
                        [
                            12.6951703678,
                            56.0306015903
                        ],
                        [
                            12.6951162721,
                            56.0306111549
                        ],
                        [
                            12.6950621763,
                            56.0306207196
                        ],
                        [
                            12.6950080805,
                            56.0306302842
                        ],
                        [
                            12.6949539847,
                            56.0306398488
                        ],
                        [
                            12.6948998889,
                            56.0306494133
                        ],
                        [
                            12.694845793,
                            56.0306589779
                        ],
                        [
                            12.6947916971,
                            56.0306685424
                        ],
                        [
                            12.6947376012,
                            56.0306781069
                        ],
                        [
                            12.6946835052,
                            56.0306876713
                        ],
                        [
                            12.6946294092,
                            56.0306972358
                        ],
                        [
                            12.6945753132,
                            56.0307068002
                        ],
                        [
                            12.6945212172,
                            56.0307163646
                        ],
                        [
                            12.6944671211,
                            56.030725929
                        ],
                        [
                            12.6944130251,
                            56.0307354933
                        ],
                        [
                            12.6943589289,
                            56.0307450577
                        ],
                        [
                            12.6943048328,
                            56.030754622
                        ],
                        [
                            12.6942507366,
                            56.0307641862
                        ],
                        [
                            12.6941966404,
                            56.0307737505
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 48.2818690819,
                    id: 25
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6941966404,
                            56.0307737505
                        ],
                        [
                            12.6941649666,
                            56.0307791633
                        ],
                        [
                            12.6941316319,
                            56.0307838553
                        ],
                        [
                            12.6940968484,
                            56.030787059
                        ],
                        [
                            12.694060812,
                            56.0307882887
                        ],
                        [
                            12.6940237056,
                            56.0307873069
                        ],
                        [
                            12.6939857006,
                            56.0307840899
                        ],
                        [
                            12.6939469602,
                            56.0307787938
                        ],
                        [
                            12.6939076408,
                            56.0307717201
                        ],
                        [
                            12.6938678954,
                            56.0307632821
                        ],
                        [
                            12.6938278752,
                            56.0307539703
                        ],
                        [
                            12.6937877326,
                            56.0307443186
                        ],
                        [
                            12.6937476228,
                            56.0307348697
                        ],
                        [
                            12.6937077073,
                            56.0307261418
                        ],
                        [
                            12.6936681551,
                            56.0307185935
                        ],
                        [
                            12.693629146,
                            56.0307125905
                        ],
                        [
                            12.6935908726,
                            56.030708371
                        ],
                        [
                            12.6935535426,
                            56.0307060118
                        ],
                        [
                            12.6935173816,
                            56.0307053939
                        ],
                        [
                            12.6934826349,
                            56.0307061688
                        ],
                        [
                            12.6934495706,
                            56.030707724
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 19.4833262047,
                    id: 26
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6934495706,
                            56.030707724
                        ],
                        [
                            12.6934340115,
                            56.0307085319
                        ],
                        [
                            12.6934184524,
                            56.0307093399
                        ],
                        [
                            12.6934028934,
                            56.0307101479
                        ],
                        [
                            12.6933873343,
                            56.0307109559
                        ],
                        [
                            12.6933717752,
                            56.0307117638
                        ],
                        [
                            12.6933562161,
                            56.0307125718
                        ],
                        [
                            12.6933406571,
                            56.0307133798
                        ],
                        [
                            12.693325098,
                            56.0307141877
                        ],
                        [
                            12.6933095389,
                            56.0307149957
                        ],
                        [
                            12.6932939799,
                            56.0307158037
                        ],
                        [
                            12.6932784208,
                            56.0307166116
                        ],
                        [
                            12.6932628617,
                            56.0307174196
                        ],
                        [
                            12.6932473026,
                            56.0307182275
                        ],
                        [
                            12.6932317435,
                            56.0307190355
                        ],
                        [
                            12.6932161845,
                            56.0307198434
                        ],
                        [
                            12.6932006254,
                            56.0307206514
                        ],
                        [
                            12.6931850663,
                            56.0307214593
                        ],
                        [
                            12.6931695072,
                            56.0307222673
                        ],
                        [
                            12.6931539482,
                            56.0307230752
                        ],
                        [
                            12.6931383891,
                            56.0307238832
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 60.7619064394,
                    id: 27
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6931383891,
                            56.0307238832
                        ],
                        [
                            12.6930950115,
                            56.0307262769
                        ],
                        [
                            12.6930516917,
                            56.0307294505
                        ],
                        [
                            12.6930077933,
                            56.0307340493
                        ],
                        [
                            12.6929630276,
                            56.0307405306
                        ],
                        [
                            12.6929174049,
                            56.0307491842
                        ],
                        [
                            12.6928711852,
                            56.0307601526
                        ],
                        [
                            12.6928248288,
                            56.0307734511
                        ],
                        [
                            12.6927789479,
                            56.0307889888
                        ],
                        [
                            12.6927342565,
                            56.0308065882
                        ],
                        [
                            12.6926915222,
                            56.0308260059
                        ],
                        [
                            12.6926515164,
                            56.030846953
                        ],
                        [
                            12.6926149655,
                            56.0308691153
                        ],
                        [
                            12.6925825016,
                            56.0308921735
                        ],
                        [
                            12.6925546136,
                            56.0309158238
                        ],
                        [
                            12.6925315977,
                            56.0309397984
                        ],
                        [
                            12.6925135087,
                            56.0309638851
                        ],
                        [
                            12.6925001105,
                            56.0309879484
                        ],
                        [
                            12.6924908272,
                            56.0310119495
                        ],
                        [
                            12.6924846938,
                            56.0310359666
                        ],
                        [
                            12.6924803071,
                            56.0310602154
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 65.887650588,
                    id: 28
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6924803071,
                            56.0310602154
                        ],
                        [
                            12.692475372,
                            56.0310896739
                        ],
                        [
                            12.6924704368,
                            56.0311191324
                        ],
                        [
                            12.6924655015,
                            56.031148591
                        ],
                        [
                            12.6924605663,
                            56.0311780495
                        ],
                        [
                            12.6924556311,
                            56.031207508
                        ],
                        [
                            12.6924506959,
                            56.0312369666
                        ],
                        [
                            12.6924457606,
                            56.0312664251
                        ],
                        [
                            12.6924408254,
                            56.0312958836
                        ],
                        [
                            12.6924358901,
                            56.0313253422
                        ],
                        [
                            12.6924321563,
                            56.0313476296
                        ],
                        [
                            12.6924309549,
                            56.0313548007
                        ],
                        [
                            12.6924260196,
                            56.0313842592
                        ],
                        [
                            12.6924210843,
                            56.0314137178
                        ],
                        [
                            12.6924161491,
                            56.0314431763
                        ],
                        [
                            12.6924112138,
                            56.0314726348
                        ],
                        [
                            12.6924062785,
                            56.0315020934
                        ],
                        [
                            12.6924013432,
                            56.0315315519
                        ],
                        [
                            12.6923964079,
                            56.0315610104
                        ],
                        [
                            12.6923914725,
                            56.0315904689
                        ],
                        [
                            12.6923865372,
                            56.0316199275
                        ],
                        [
                            12.6923816019,
                            56.031649386
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 68.4896756221,
                    id: 34
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6922236033,
                            56.0316354601
                        ],
                        [
                            12.6922379903,
                            56.031609853
                        ],
                        [
                            12.6922522466,
                            56.0315828629
                        ],
                        [
                            12.6922656035,
                            56.0315546404
                        ],
                        [
                            12.6922775257,
                            56.0315253325
                        ],
                        [
                            12.6922876852,
                            56.0314950829
                        ],
                        [
                            12.6922959349,
                            56.0314640328
                        ],
                        [
                            12.6923022825,
                            56.0314323222
                        ],
                        [
                            12.6923068636,
                            56.0314000904
                        ],
                        [
                            12.6923099159,
                            56.0313674768
                        ],
                        [
                            12.6923117528,
                            56.031334622
                        ],
                        [
                            12.6923127368,
                            56.0313016682
                        ],
                        [
                            12.6923132535,
                            56.0312687604
                        ],
                        [
                            12.692313685,
                            56.031236047
                        ],
                        [
                            12.6923143836,
                            56.0312036808
                        ],
                        [
                            12.6923156458,
                            56.0311718197
                        ],
                        [
                            12.6923176854,
                            56.0311406276
                        ],
                        [
                            12.6923206079,
                            56.0311102752
                        ],
                        [
                            12.6923243834,
                            56.0310809407
                        ],
                        [
                            12.6923288207,
                            56.031052811
                        ],
                        [
                            12.6923335412,
                            56.031026082
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 63.9624578441,
                    id: 35
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6923335412,
                            56.031026082
                        ],
                        [
                            12.692339108,
                            56.0309963945
                        ],
                        [
                            12.6923457535,
                            56.0309672904
                        ],
                        [
                            12.6923544597,
                            56.0309386813
                        ],
                        [
                            12.6923659723,
                            56.0309105767
                        ],
                        [
                            12.6923808227,
                            56.0308830674
                        ],
                        [
                            12.6923993514,
                            56.0308563097
                        ],
                        [
                            12.6924217307,
                            56.0308305088
                        ],
                        [
                            12.6924479876,
                            56.0308059031
                        ],
                        [
                            12.6924780265,
                            56.0307827477
                        ],
                        [
                            12.6925116522,
                            56.0307612985
                        ],
                        [
                            12.6925485928,
                            56.0307417958
                        ],
                        [
                            12.6925885226,
                            56.0307244482
                        ],
                        [
                            12.6926310847,
                            56.0307094167
                        ],
                        [
                            12.692675914,
                            56.0306967981
                        ],
                        [
                            12.6927226602,
                            56.0306866092
                        ],
                        [
                            12.6927710104,
                            56.0306787705
                        ],
                        [
                            12.6928207122,
                            56.03067309
                        ],
                        [
                            12.6928715964,
                            56.0306692471
                        ],
                        [
                            12.6929235999,
                            56.0306667764
                        ],
                        [
                            12.6929767886,
                            56.0306650516
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 38.0788819426,
                    id: 36
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6929767886,
                            56.0306650516
                        ],
                        [
                            12.6930072851,
                            56.030664143
                        ],
                        [
                            12.6930377816,
                            56.0306632344
                        ],
                        [
                            12.6930682781,
                            56.0306623258
                        ],
                        [
                            12.6930987746,
                            56.0306614172
                        ],
                        [
                            12.6931292711,
                            56.0306605086
                        ],
                        [
                            12.6931597676,
                            56.0306596
                        ],
                        [
                            12.6931902641,
                            56.0306586914
                        ],
                        [
                            12.6932207606,
                            56.0306577827
                        ],
                        [
                            12.6932512571,
                            56.0306568741
                        ],
                        [
                            12.6932817536,
                            56.0306559654
                        ],
                        [
                            12.6933122501,
                            56.0306550568
                        ],
                        [
                            12.6933427466,
                            56.0306541481
                        ],
                        [
                            12.693373243,
                            56.0306532394
                        ],
                        [
                            12.6934037395,
                            56.0306523308
                        ],
                        [
                            12.693434236,
                            56.0306514221
                        ],
                        [
                            12.6934647325,
                            56.0306505134
                        ],
                        [
                            12.693495229,
                            56.0306496047
                        ],
                        [
                            12.6935257255,
                            56.030648696
                        ],
                        [
                            12.6935562219,
                            56.0306477872
                        ],
                        [
                            12.6935867184,
                            56.0306468785
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 46.0401585627,
                    id: 37
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6935867184,
                            56.0306468785
                        ],
                        [
                            12.6936126759,
                            56.0306462488
                        ],
                        [
                            12.693640716,
                            56.0306463274
                        ],
                        [
                            12.6936712684,
                            56.0306476311
                        ],
                        [
                            12.6937045235,
                            56.0306504495
                        ],
                        [
                            12.6937404626,
                            56.0306548743
                        ],
                        [
                            12.6937788881,
                            56.0306608285
                        ],
                        [
                            12.6938194542,
                            56.0306680965
                        ],
                        [
                            12.6938616968,
                            56.0306763537
                        ],
                        [
                            12.6939050641,
                            56.0306851956
                        ],
                        [
                            12.6939489468,
                            56.0306941676
                        ],
                        [
                            12.6939927085,
                            56.0307027949
                        ],
                        [
                            12.6940357161,
                            56.0307106115
                        ],
                        [
                            12.6940773697,
                            56.0307171902
                        ],
                        [
                            12.6941171335,
                            56.0307221721
                        ],
                        [
                            12.6941545659,
                            56.0307252959
                        ],
                        [
                            12.6941893495,
                            56.0307264278
                        ],
                        [
                            12.6942213219,
                            56.0307255911
                        ],
                        [
                            12.6942505059,
                            56.0307229953
                        ],
                        [
                            12.6942771395,
                            56.0307190663
                        ],
                        [
                            12.6943017068,
                            56.0307144755
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 62.3655353541,
                    id: 38
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6943017068,
                            56.0307144755
                        ],
                        [
                            12.6943489457,
                            56.0307052703
                        ],
                        [
                            12.6943961846,
                            56.0306960652
                        ],
                        [
                            12.6944434234,
                            56.03068686
                        ],
                        [
                            12.6944906622,
                            56.0306776548
                        ],
                        [
                            12.694537901,
                            56.0306684496
                        ],
                        [
                            12.6945851398,
                            56.0306592443
                        ],
                        [
                            12.6946323785,
                            56.0306500391
                        ],
                        [
                            12.6946796172,
                            56.0306408338
                        ],
                        [
                            12.6947268559,
                            56.0306316285
                        ],
                        [
                            12.6947740946,
                            56.0306224232
                        ],
                        [
                            12.6948213332,
                            56.0306132179
                        ],
                        [
                            12.6948685719,
                            56.0306040125
                        ],
                        [
                            12.6949158105,
                            56.0305948071
                        ],
                        [
                            12.694963049,
                            56.0305856018
                        ],
                        [
                            12.6950102876,
                            56.0305763963
                        ],
                        [
                            12.6950575261,
                            56.0305671909
                        ],
                        [
                            12.6951047647,
                            56.0305579855
                        ],
                        [
                            12.6951520031,
                            56.03054878
                        ],
                        [
                            12.6951992416,
                            56.0305395746
                        ],
                        [
                            12.6952464801,
                            56.0305303691
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 80.5619671421,
                    id: 39
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6952464801,
                            56.0305303691
                        ],
                        [
                            12.6952988974,
                            56.0305201933
                        ],
                        [
                            12.6953544365,
                            56.0305096151
                        ],
                        [
                            12.6954126871,
                            56.0304988476
                        ],
                        [
                            12.6954732519,
                            56.0304880315
                        ],
                        [
                            12.6955357453,
                            56.0304772445
                        ],
                        [
                            12.6955997927,
                            56.0304665118
                        ],
                        [
                            12.6956650292,
                            56.0304558156
                        ],
                        [
                            12.6957310989,
                            56.0304451057
                        ],
                        [
                            12.6957976541,
                            56.0304343087
                        ],
                        [
                            12.6958643541,
                            56.030423339
                        ],
                        [
                            12.6959308644,
                            56.0304121077
                        ],
                        [
                            12.6959968556,
                            56.0304005334
                        ],
                        [
                            12.6960620029,
                            56.0303885519
                        ],
                        [
                            12.6961259846,
                            56.0303761261
                        ],
                        [
                            12.6961884816,
                            56.0303632563
                        ],
                        [
                            12.6962491762,
                            56.0303499898
                        ],
                        [
                            12.6963077513,
                            56.0303364311
                        ],
                        [
                            12.6963638895,
                            56.030322752
                        ],
                        [
                            12.6964172719,
                            56.0303092013
                        ],
                        [
                            12.6964675777,
                            56.0302961151
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 5.130671174,
                    id: 40
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6923816019,
                            56.031649386
                        ],
                        [
                            12.6923812808,
                            56.0316513667
                        ],
                        [
                            12.6923810005,
                            56.0316534553
                        ],
                        [
                            12.6923808074,
                            56.0316556339
                        ],
                        [
                            12.6923807326,
                            56.0316578865
                        ],
                        [
                            12.6923807931,
                            56.031660199
                        ],
                        [
                            12.6923809943,
                            56.0316625591
                        ],
                        [
                            12.6923813311,
                            56.0316649558
                        ],
                        [
                            12.69238179,
                            56.031667379
                        ],
                        [
                            12.6923823506,
                            56.0316698196
                        ],
                        [
                            12.6923829879,
                            56.031672269
                        ],
                        [
                            12.6923836733,
                            56.0316747189
                        ],
                        [
                            12.6923843771,
                            56.031677161
                        ],
                        [
                            12.6923850698,
                            56.0316795865
                        ],
                        [
                            12.6923857239,
                            56.0316819862
                        ],
                        [
                            12.692386316,
                            56.0316843503
                        ],
                        [
                            12.6923868281,
                            56.0316866674
                        ],
                        [
                            12.6923872497,
                            56.0316889252
                        ],
                        [
                            12.6923875793,
                            56.0316911094
                        ],
                        [
                            12.6923878266,
                            56.0316932041
                        ],
                        [
                            12.6923880137,
                            56.0316951909
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 16.1286405475,
                    id: 41
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6923880137,
                            56.0316951909
                        ],
                        [
                            12.6923884911,
                            56.0317015437
                        ],
                        [
                            12.6923888243,
                            56.0317082106
                        ],
                        [
                            12.6923889915,
                            56.0317151338
                        ],
                        [
                            12.6923889858,
                            56.0317222642
                        ],
                        [
                            12.6923888116,
                            56.03172956
                        ],
                        [
                            12.6923884834,
                            56.0317369856
                        ],
                        [
                            12.692388022,
                            56.0317445108
                        ],
                        [
                            12.6923874532,
                            56.0317521091
                        ],
                        [
                            12.6923868043,
                            56.0317597569
                        ],
                        [
                            12.6923861025,
                            56.0317674325
                        ],
                        [
                            12.6923853718,
                            56.0317751143
                        ],
                        [
                            12.6923846306,
                            56.0317827804
                        ],
                        [
                            12.6923838895,
                            56.0317904071
                        ],
                        [
                            12.6923831487,
                            56.0317979677
                        ],
                        [
                            12.6923823953,
                            56.0318054313
                        ],
                        [
                            12.692381601,
                            56.0318127621
                        ],
                        [
                            12.6923807197,
                            56.0318199177
                        ],
                        [
                            12.6923796848,
                            56.0318268481
                        ],
                        [
                            12.6923784069,
                            56.0318334948
                        ],
                        [
                            12.6923767712,
                            56.0318397895
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 39.3218079004,
                    id: 43
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6923767712,
                            56.0318397895
                        ],
                        [
                            12.6923680086,
                            56.0318583645
                        ],
                        [
                            12.692353636,
                            56.0318752999
                        ],
                        [
                            12.6923342237,
                            56.0318898312
                        ],
                        [
                            12.6923105345,
                            56.0319014115
                        ],
                        [
                            12.6922834846,
                            56.0319096899
                        ],
                        [
                            12.6922541042,
                            56.0319144896
                        ],
                        [
                            12.6922234979,
                            56.0319157857
                        ],
                        [
                            12.6921928057,
                            56.0319136841
                        ],
                        [
                            12.6921631635,
                            56.0319083991
                        ],
                        [
                            12.6921356635,
                            56.0319002318
                        ],
                        [
                            12.6921113153,
                            56.031889548
                        ],
                        [
                            12.6920910061,
                            56.0318767569
                        ],
                        [
                            12.6920754615,
                            56.0318622887
                        ],
                        [
                            12.6920652064,
                            56.0318465732
                        ],
                        [
                            12.6920605252,
                            56.0318300178
                        ],
                        [
                            12.6920614226,
                            56.0318129855
                        ],
                        [
                            12.6920675843,
                            56.0317957734
                        ],
                        [
                            12.6920783379,
                            56.0317785906
                        ],
                        [
                            12.6920926128,
                            56.0317615366
                        ],
                        [
                            12.6921089016,
                            56.0317445793
                        ]
                    ]
                }
            },
            {
                type: "Feature",
                properties: {
                    length: 14.1674399589,
                    id: 44
                },
                geometry: {
                    type: "LineString",
                    coordinates: [
                        [
                            12.6921089016,
                            56.0317445793
                        ],
                        [
                            12.6921136971,
                            56.0317397346
                        ],
                        [
                            12.6921188667,
                            56.0317346768
                        ],
                        [
                            12.6921244542,
                            56.0317294716
                        ],
                        [
                            12.6921304654,
                            56.0317241681
                        ],
                        [
                            12.6921368736,
                            56.0317188006
                        ],
                        [
                            12.6921436245,
                            56.0317133914
                        ],
                        [
                            12.6921506416,
                            56.0317079525
                        ],
                        [
                            12.6921578316,
                            56.0317024883
                        ],
                        [
                            12.6921650895,
                            56.0316969975
                        ],
                        [
                            12.6921723041,
                            56.0316914751
                        ],
                        [
                            12.692179363,
                            56.0316859154
                        ],
                        [
                            12.692186158,
                            56.0316803132
                        ],
                        [
                            12.6921925906,
                            56.0316746668
                        ],
                        [
                            12.6921985766,
                            56.03166898
                        ],
                        [
                            12.6922040523,
                            56.0316632641
                        ],
                        [
                            12.6922089789,
                            56.0316575402
                        ],
                        [
                            12.6922133485,
                            56.0316518418
                        ],
                        [
                            12.6922171887,
                            56.0316462165
                        ],
                        [
                            12.6922205686,
                            56.0316407283
                        ],
                        [
                            12.6922236033,
                            56.0316354601
                        ]
                    ]
                }
            }
        ]
    },
    charging_points: {
        type: "FeatureCollection",
        features: []
    },
    areas: {
        type: "FeatureCollection",
        features: []
    }
}

export default site;