import "./HelpContent.css";
export default function JobsManagementPage() {
    return (
        <div className="help-content-container">
            <p>The Jobs section provides a comprehensive view of all jobs within the system. It's a central hub for tracking and managing jobs.</p>
            <p><img src={require("assets/img/jobsPage.png")} alt="Jobs page" style={{ width: '80%' }} /></p>
            <h5><b>1. Job ID Column</b></h5>
            <p>The Job ID column is your unique identifier for each task or mission within the system. It helps you differentiate and keep track of individual jobs.</p>
            <h5><b>2. Vehicle Column</b></h5>
            <p>The Vehicle column provides information about the assigned vehicle for each job. It tells you which truck is responsible for executing the task.</p>
            <h5><b>3. Progress Column</b></h5>
            <p>Jobs can have various statuses, including "Created," "Sent," "Validated," "Ongoing," "Completed," "Canceled," and "Rejected." Each status reflects a different stage of the job.
                <br /> Here's an explanation of these statuses:</p>
            <p>
                <b>Created:</b><br />
                The "Created" status indicates that the job order has been generated but not yet processed. The job is in the system but has not been assigned or initiated.
            </p>
            <p>
                <b>Validated:</b><br />
                "Validated" implies that the job order has been reviewed and approved. It's ready for execution, and the vehicle is set to complete the task.
            </p>
            <p>
                <b>Sent:</b><br />
                The "Sent" status reflects that the job order has been transmitted to the vehicle for execution. The vehicle has received the task and is preparing to carry it out.
            </p>
            <p>
                <b>Ongoing:</b><br />
                When a job is "Ongoing" it means that the task is actively in progress. The assigned vehicle is executing the job, and the system is closely monitoring its advancement.
            </p>
            <p>
                <b>Canceled:</b><br />
                Jobs in the "Canceled" means that the task will not proceed further, and the vehicle's assignment is canceled.
            </p>
            <p>
                <b>Rejected:</b><br />
                When a job is marked as "Rejected" it signifies that the system has declined the task, and it won't be executed. The reasons for rejection include the assigned truck being already engaged in a mission and, at times, conflicts or issues.
            </p>
            <p>
                <b>Completed:</b><br />
                The "Completed" status indicates that the job has been successfully executed. The assigned vehicle has finished its task, and the cargo has reached its destination.
            </p>
            <h5><b>4. Destination Column</b></h5>
            <p>The Destination column specifies where each job is headed. It's a quick reference to understand the ultimate goal of a mission or task.</p>
            <h5><b>5. Destination Longitude and Latitude Columns</b></h5>
            <p>These are geographical coordinates that pinpoint the exact location of a destination. "Longitude" refers to the east-west position on the Earth's surface, and "Latitude" refers to the north-south position.</p>
            <h5><b>6. FMS ID Column </b></h5>
            <p>The "FMS ID" refers to a unique identifier for the job itself within the Fleet Management System.</p>
            <h5><b>7. Created Column</b></h5>
            <p>The "Created" column provides the date and time when a job is initially created within the system. It allows users to track the chronological order of job creation, which can be helpful for reference and organization.</p>
            <h5><b>8. Creator Column</b></h5>
            <p>This column indicates who created the job, and there are three possible cases: a user, TOS, or the AVMS (in cases where it creates a split of an initial job created by a user or TOS)</p>
            <h5><b>9. Canceling Jobs and Transiting to the Map</b></h5>
            <p>You can cancel or delete tasks by selecting the respective option. To transition to the live map for a real-time view, simply choose the 'Map' option. This enables you to visualize vehicle movements and job progress.</p>
            <p><img src={require("assets/img/jobsPageActions.png")} alt="Jobs page actions" style={{ width: '80%' }} /></p>
        </div>
    )
};