import { ALARMS_VIEW_PERMISSION, DASHBOARD_VIEW_PERMISSION, HELP_VIEW_PERMISSION, JOBS_VIEW_PERMISSION, LIVE_MAP_VIEW_PERMISSION, PARAMETERS_VIEW_PERMISSION, TWISTLOCKS_VIEW_PERMISSION, USERS_VIEW_PERMISSION, VEHICLES_VIEW_PERMISSION } from "constants/PERMISSIONS";
import Alarms from "views/Alarms/Alarms";
import Dashboard from "views/Dashboard/Dashboard.js";
import Help from "views/Help/Help";
import AlarmsAndNotificationsPage from "views/Help/content/AlarmsAndNotificationsPage";
import JobsManagementPage from "views/Help/content/JobsManagementPage";
import LiveMapPage from "views/Help/content/LiveMapPage";
import OverviewSolutionPage from "views/Help/content/OverviewSolutionPage";
import SystemConfigurationPage from "views/Help/content/SystemConfigurationPage";
import TwistlocksPage from "views/Help/content/TwistlocksPage";
import UserManagementPage from "views/Help/content/UserManagementPage";
import VehicleManagementPage from "views/Help/content/VehicleManagementPage";
import Jobs from "views/Jobs/Jobs";
import Maps from "views/Maps/Maps.js";
import Settings from "views/Settings/Settings";
import Twistlocks from "views/Twistlocks/Twistlocks";
import Vehicles from "views/Vehicles/Vehicles";


export const getRoutesByPermissions = (permissions) => {
  const dashboardRoutes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: "nc-icon nc-chart-pie-35",
      component: Dashboard,
      layout: "/admin",
      visible: permissions.includes(DASHBOARD_VIEW_PERMISSION),
      mainRoute: true
    },
    {
      path: "/liveMap",
      name: "Live Map",
      icon: "nc-icon nc-pin-3",
      component: Maps,
      layout: "/admin",
      visible: permissions.includes(LIVE_MAP_VIEW_PERMISSION),
      mainRoute: true
    },
    {
      path: "/jobs",
      name: "Jobs",
      icon: "nc-icon nc-notes",
      component: Jobs,
      layout: "/admin",
      visible: permissions.includes(JOBS_VIEW_PERMISSION),
      mainRoute: true
    },
    {
      path: "/vehicles",
      name: "Vehicles",
      icon: "nc-icon nc-bus-front-12",
      component: Vehicles,
      layout: "/admin",
      visible: permissions.includes(VEHICLES_VIEW_PERMISSION),
      mainRoute: true
    },
    {
      path: "/twistlocks",
      name: "Twistlocks",
      icon: "nc-icon nc-lock-circle-open",
      component: Twistlocks,
      layout: "/admin",
      visible: permissions.includes(TWISTLOCKS_VIEW_PERMISSION),
      mainRoute: true
    },
    {
      path: "/alarms",
      name: "Alarms",
      icon: "nc-icon nc-time-alarm",
      component: Alarms,
      layout: "/admin",
      visible: permissions.includes(ALARMS_VIEW_PERMISSION),
      mainRoute: true
    },
    {
      path: "/settings",
      name: "Settings",
      icon: "nc-icon nc-settings-gear-64",
      component: Settings,
      layout: "/admin",
      visible: permissions.includes(PARAMETERS_VIEW_PERMISSION) || permissions.includes(USERS_VIEW_PERMISSION),
      mainRoute: true
    },
    {
      path: "/help",
      name: "Help",
      icon: "nc-icon nc-zoom-split",
      component: Help,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: true
    },
    {
      path: "/user-management",
      name: "User management",
      component: UserManagementPage,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: false
    },
    {
      path: "/overview-solution",
      name: "Overview of the solution",
      component: OverviewSolutionPage,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: false
    },
    {
      path: "/live-map",
      name: "Live Map",
      component: LiveMapPage,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: false
    },
    {
      path: "/jobs-management",
      name: "Jobs Management",
      component: JobsManagementPage,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: false
    },
    {
      path: "/vehicle-management",
      name: "Vehicle Section",
      component: VehicleManagementPage,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: false
    },
    {
      path: "/twistlocks-help",
      name: "Twistlocks",
      component: TwistlocksPage,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: false
    },
    {
      path: "/alarms-notifications",
      name: "Alarms and notifications",
      component: AlarmsAndNotificationsPage,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: false
    },
    {
      path: "/system-configuration",
      name: "System configuration",
      component: SystemConfigurationPage,
      layout: "/admin",
      visible: permissions.includes(HELP_VIEW_PERMISSION),
      mainRoute: false
    }
    /*
    {
      path: "/typography",
      name: "Typography",
      icon: "nc-icon nc-paper-2",
      component: Typography,
      layout: "/admin"
    },
    {
      path: "/icons",
      name: "Icons",
      icon: "nc-icon nc-atom",
      component: Icons,
      layout: "/admin"
    },
    {
      path: "/notifications",
      name: "Notifications",
      icon: "nc-icon nc-bell-55",
      component: Notifications,
      layout: "/admin"
    }*/
  ];
  return dashboardRoutes.filter(route => route.visible === true);
}


