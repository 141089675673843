import { useState } from 'react';
// @mui
import { Alert, Box, Divider, MenuItem, Popover, Snackbar, Stack, Typography } from '@mui/material';
import { useUser } from 'hooks/useUser';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import AuthService from 'services/auth.service';
import './AccountPopover.css';
const account = {
  displayName: 'Port of Helsingborg',
  site: 'Site: Helsingborg'
};
const authService = new AuthService();
export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [openToast, setOpenToast] = useState(false);
  const [signoutErrorMessage, setSignoutErrorMessage] = useState(null);
  const history = useHistory();
  const { getUser, removeUser } = useUser();
  const goToHelpPage = () => {
    history.push("/admin/help")
  };

  const MENU_OPTIONS = [
    {
      label: 'Help',
      icon: 'eva:question-mark-circle-outline',
      onClick: goToHelpPage
    }
  ];
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    const user = getUser();
    const accessToken = JSON.parse(user).accessToken;
    authService.signout(accessToken).then(response => {
      if (response.status === 200) {
        removeUser();
        history.push("/login")
      } else {
        setSignoutErrorMessage(response.data.message);
        setOpen(false);
        setOpenToast(true);
      }
    });
  };


  const handleToastClose = () => {
    setOpenToast(false);
  };

  return (
    <>
      <div className="logo-img" onClick={handleOpen}>
        <img src={require("assets/img/helsingborgs-hamn_logo_no-bg.png")} alt="..." />
      </div>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.site}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={option.onClick}>
              <Typography>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleToastClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
          {signoutErrorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
