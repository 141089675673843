
import { Button, Card, Container, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Popover, Select, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField } from "@mui/material";
import CustomizeModal from 'components/Modal/CustomizeModal';
import Search from "components/Search/Search";
import Spinner from "components/Spinner/Spinner";
import TableHeader from "components/TableHeader/TableHeader";
import Iconify from "components/iconify/Iconify";
import { ROWS_PERS_PAGE_OPTIONS } from "constants/COMMON";
import { USERS_ACTION_ADD_PERMISSION, USERS_ACTION_DELETE_PERMISSION, USERS_ACTION_EDIT_PERMISSION } from "constants/PERMISSIONS";
import { useUser } from "hooks/useUser";
import { filter, uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import UserService from "services/users.service";
import { pageCountDisplay } from "utils/utilFunctions";
import './Users.css';
const TABLE_HEAD = [
    { id: 'username', label: 'Username', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (item) =>
            item.username.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.role.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
};

const CREATE_USER_MODE = 'CREATE';
const UPDATE_USER_MODE = 'UPDATE';

const roles = ['ADMIN', 'MANAGER', 'DISPATCHER', 'LASHER'];

const userService = new UserService();
const TextHelperInputErrorMessage = ({ text }) => {
    return <span style={{ color: '#d32f2f' }}>{text}</span>
};

export default function Users() {
    const { getUserPermissions } = useUser();
    const permissions = getUserPermissions();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState('asc');

    const [orderBy, setOrderBy] = useState('username');

    const [filterQuery, setFilterQuery] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PERS_PAGE_OPTIONS[0]);

    const [users, setUsers] = useState([]);
    const [filterUsers, setFilterUsers] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [openCreateOrUpdateUserModal, setOpenCreateOrUpdateUserModal] = useState(false);
    const [createOrUpdateUserModalType, setCreateOrUpdateUserModalType] = useState(CREATE_USER_MODE);
    const [currentUser, setCurrentUser] = useState({ username: null, email: null, password: null, role: null });
    const [showWarningModal, setShowWarningModal] = useState(false);
    useEffect(() => {
        userService.fetchAll().then(data => {
            if (data.status === 200) {
                const filteredData = applySortFilter(data.data ?? [], getComparator(order, orderBy), filterQuery);
                setUsers([...filteredData]);
                setFilterUsers([...filteredData]);
                setLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        const filteredData = applySortFilter(users, getComparator(order, orderBy), filterQuery);
        setFilterUsers(filteredData);
    }, [order, orderBy, filterQuery]);

    const handleOpenMenu = (event, user) => {
        setOpen(event.currentTarget);
        setCurrentUser(prev => ({ ...prev, email: user.email, username: user.username, role: user.role }))
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleQueryFilter = (event) => {
        const query = event.target.value;
        setPage(0);
        setFilterQuery(query);
        if (query === null || query === '') {
            setFilterUsers(users);
            return;
        }
        sortAndFilterData();
    };

    const sortAndFilterData = () => {
        const data = applySortFilter(users, getComparator(order, orderBy), filterQuery);
        setFilterUsers([...data]);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;


    const isNotFound = users.length === 0;

    const handleDeleteUser = () => {
        const username = currentUser.username;
        userService.delete(username).then((response) => {
            if (response.status === 202) {
                const data = users.filter(user => user.username !== username);
                setFilterUsers(data);
                setUsers(data);
                setShowWarningModal(false);
                setCurrentUser({ username: null, email: null, password: null, role: null });
            }
        });

    };

    const confirmDeleteUser = () => {
        setOpen(false);
        setShowWarningModal(true);
    };

    const handleAddOrUpdateUser = (mode) => {
        setOpenCreateOrUpdateUserModal(true);
        setCreateOrUpdateUserModalType(mode);
    };

    const handleCloseCreateOrUpdateUserModal = () => {
        setOpenCreateOrUpdateUserModal(false);
        setCurrentUser({ username: null, email: null, password: null, role: null });
        setOpen(false);
    };

    const onUserChange = ($event) => {
        const value = $event.target.value;
        setCurrentUser({
            ...currentUser,
            [$event.target.name]: value
        });
    };

    const handleUserCreateOrUpdateSubmit = () => {
        if (createOrUpdateUserModalType === UPDATE_USER_MODE) {
            const prevUser = users.find(user => user.username === currentUser.username);
            const payload = { username: currentUser.username, email: currentUser.email, currentRole: currentUser.role, prevRole: prevUser.role }
            userService.put(payload).then((response) => {
                if (response.status === 200) {
                    const data = users.map(user => user.username === currentUser.username ? currentUser : user);
                    setUsers(data);
                    setFilterUsers(data);
                    handleCloseCreateOrUpdateUserModal();
                }
            });
        } else {
            userService.post(currentUser).then((response) => {
                if (response.status === 201) {
                    const data = [...users, currentUser];
                    setUsers(data);
                    setFilterUsers(data);
                    handleCloseCreateOrUpdateUserModal();
                }
            });
        }
    };

    const rightClickHandler = (event, row) => {
        event.preventDefault();
        setOpen(event.target);
        setCurrentUser(row);
    };

    return <>

        <title>Users table</title>
        <Container className="table-container">
            <Card>
                <div className="table-search-header">
                    <Search filterQuery={filterQuery} placeholder={'Search users ...'} onFilterChange={handleQueryFilter}
                        isDeleteButtonVisible={true} />

                    {permissions.includes(USERS_ACTION_ADD_PERMISSION) && <Button className="add-user-btn" variant="contained" color='success' onClick={() => handleAddOrUpdateUser(CREATE_USER_MODE)} >
                        Add user
                    </Button>}

                </div>
                {loading ? <Spinner /> :
                    <>
                        <TableContainer>
                            <Table className="users-table" size='small' stickyHeader={true}>
                                <TableHeader
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={users.length}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {filterUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { username, email, role } = row;
                                        return (
                                            <TableRow hover key={username} onContextMenu={(event) => rightClickHandler(event, row)}>
                                                <TableCell align='left' sx={{ width: 1 / 5 }}>{username}</TableCell>

                                                <TableCell align="left" sx={{ width: 1 / 2 }}>{email}</TableCell>
                                                <TableCell align="left" sx={{ width: 1 / 5 }}>{role}</TableCell>
                                                {(permissions.includes(USERS_ACTION_EDIT_PERMISSION) || permissions.includes(USERS_ACTION_DELETE_PERMISSION)) && <TableCell align="right">
                                                    <IconButton size="large" color="inherit" onClick={($event) => handleOpenMenu($event, row)}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                )}

                            </Table>

                        </TableContainer>
                        <TablePagination
                            className='table-pagination'
                            rowsPerPageOptions={ROWS_PERS_PAGE_OPTIONS}
                            component="div"
                            count={users.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={() => pageCountDisplay(users.length, page, rowsPerPage)}
                        />
                    </>}
            </Card>
        </Container>

        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 140,
                    '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                },
            }}
        >
            {permissions.includes(USERS_ACTION_EDIT_PERMISSION) && <MenuItem onClick={() => handleAddOrUpdateUser(UPDATE_USER_MODE)}>
                <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
                Edit
            </MenuItem>}
            {permissions.includes(USERS_ACTION_EDIT_PERMISSION) && <MenuItem sx={{ color: 'error.main' }} onClick={confirmDeleteUser}>
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                Delete
            </MenuItem>}
        </Popover>

        <CustomizeModal open={openCreateOrUpdateUserModal}
            title={createOrUpdateUserModalType === UPDATE_USER_MODE ? 'Update User info' : 'Create new user'}
            handleClose={handleCloseCreateOrUpdateUserModal}
            isSaveDisabled={
                createOrUpdateUserModalType === UPDATE_USER_MODE ?
                    [currentUser.email, currentUser.username, currentUser.role].some(attr => attr === null || attr === undefined || attr === '') :
                    [currentUser.email, currentUser.password, currentUser.username, currentUser.role].some(attr => attr === null || attr === undefined || attr === '')}
            handleSave={handleUserCreateOrUpdateSubmit}
        >
            <form>
                <div className="user-modal-container">
                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            id={uniqueId()}
                            type="text"
                            name="username"
                            label='Username'
                            variant="outlined"
                            disabled={createOrUpdateUserModalType === UPDATE_USER_MODE}
                            value={currentUser.username}
                            helperText={(currentUser.username === null || currentUser.username === '') ? <TextHelperInputErrorMessage text=" The username is mandatory" /> : ''}
                            onChange={onUserChange}
                            inputProps={{
                                autoComplete: 'new-password'
                            }}
                        />
                    </FormControl>

                    <FormControl sx={{ m: 1 }}>
                        <TextField
                            id={uniqueId()}
                            type="email"
                            name="email"
                            label='Email address'
                            variant="outlined"
                            value={currentUser.email}
                            helperText={(currentUser.email === null || currentUser.email === '') ? <TextHelperInputErrorMessage text="The email address is mandatory" /> : ''}
                            onChange={onUserChange}
                            inputProps={{
                                autoComplete: 'new-password'
                            }}
                        />
                    </FormControl>
                    {(createOrUpdateUserModalType === CREATE_USER_MODE) && <FormControl sx={{ m: 1 }}>
                        <TextField
                            id={uniqueId()}
                            label="Password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={currentUser.password}
                            disabled={createOrUpdateUserModalType === UPDATE_USER_MODE}
                            helperText={(currentUser.password === null || currentUser.password === '') ? <TextHelperInputErrorMessage text="The password is mandatory" /> : ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                autoComplete: 'new-password'

                            }}
                            onChange={onUserChange}
                        />
                    </FormControl>
                    }
                    <FormControl sx={{ m: 1 }}>
                        <InputLabel id="role-select-input-label">
                            Role
                        </InputLabel>
                        <Select
                            labelId="role-select-input-label"
                            label="Role"
                            id="role-select-input"
                            name='role'
                            value={currentUser.role}
                            onChange={onUserChange}
                        >
                            {
                                roles.map((role) =>
                                    <MenuItem key={role} value={role}>{role}</MenuItem>
                                )
                            }
                        </Select>
                        {(currentUser.role === null || currentUser.role === '') && <span style={{ color: '#d32f2f', fontSize: '0.75rem', marginLeft: '14px', marginTop: '3px' }}>The role is mandatory</span>}
                    </FormControl>
                </div>
            </form>
        </CustomizeModal>

        <Modal
            className="modal-mini modal-primary"
            show={showWarningModal}
            onHide={() => setShowWarningModal(false)}
        >
            <Modal.Header className="justify-content-center">
                <div className="modal-profile">
                    <i className="nc-icon nc-simple-remove remove-icon"></i>
                </div>
            </Modal.Header>
            <Modal.Body className="text-center">
                <h4>Are you sure ?</h4>
                <p>Do you want really to delete this user? This process cannot be undone.</p>
            </Modal.Body>
            <div className="modal-footer">
                <Button
                    type="button"
                    variant="contained"
                    color='inherit'
                    onClick={() => setShowWarningModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    className="btn btn-danger"
                    type="button"
                    variant="contained"
                    color='error'
                    onClick={handleDeleteUser}
                >
                    Delete
                </Button>
            </div>
        </Modal>
    </>
}