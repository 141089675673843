import PropTypes from 'prop-types';

import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

TableHeader.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    selected: PropTypes.number,
    rowCount: PropTypes.number,
    headLabel: PropTypes.array,
    isCheckBoxVisible: PropTypes.bool,
    onRequestSort: PropTypes.func,
    onSelectAllClick: PropTypes.func
}
export default function TableHeader({ order, orderBy, rowCount, headLabel, isCheckBoxVisible, selected, onRequestSort, onSelectAllClick }) {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow>
                {isCheckBoxVisible &&
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={selected > 0 && selected < rowCount}
                            checked={rowCount > 0 && selected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>}
                {headLabel.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.alignRight ? 'right' : 'left'}
                        sortDirection={order}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}