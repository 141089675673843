import secureLocalStorage from "react-secure-storage";
export const useLocalStorage = () => {

    const setItem = (key, value) => {
        secureLocalStorage.setItem(key, value);
    };

    const getItem = (key) => {
        const value = secureLocalStorage.getItem(key);
        return value;
    };

    const removeItem = (key) => {
        secureLocalStorage.removeItem(key);
    };

    return { setItem, getItem, removeItem };
};