import "./HelpContent.css";
export default function UserManagementPage() {
    return <div className="help-content-container">
        <p><img src={require("assets/img/userManagementPage.png")} alt="user management page" style={{ width: '80%' }} /></p>
        <p>Different users have varying roles and permissions in AVMS.</p>
        <p>Understand the roles and how they impact user access and actions.</p>
        <p><img src={require("assets/img/userPermissions.jpg")} alt="user permissions" style={{ width: '80%' }} /></p>
        <h5><b>1. Adding New Users</b></h5>
        <p>To add a new user to the system, access the "New User" option, then provide a username, password, email, and select their roles. The new user will receive a confirmation link in their email, which they can use to gain access to the system.</p>
        <p><img src={require("assets/img/addNewUser.png")} alt="add new user" /></p>
        <h5><b>2. Editing User Roles</b></h5>
        <p>Need to adjust a user's role or permissions? Select the user you wish to modify, click on the "Edit" option, and then choose the specific role you want to assign to that user.</p>
        <p><img src={require("assets/img/editUserRoles.png")} alt="edit user roles" style={{ width: '80%' }} /></p>
        <h5><b>3. Deleting Users</b></h5>
        <p>To remove a user's access to the system, select the user you wish to remove, click on the "Delete" option</p>
        <p><img src={require("assets/img/deleteUser.png")} alt="delete user" style={{ width: '80%' }} /></p>
    </div>
}