import axios from 'axios';
export class HttpService {
    headers = {
    }

    async delele(url) {
        return axios.delete(`${url}`, {
            headers: this.headers
        }).then(
            (axiosResponse) => {
                return axiosResponse.data;
            }
        );
    }

    async get(url, params) {
        return axios.get(`${url}`, {
            headers: this.headers,
            params: params
        }).then((axiosResponse) => {
            return axiosResponse.data;
        });
    }

    async put(url, body) {
        return axios
            .put(`${url}`, JSON.stringify(body), {
                headers: this.headers
            })
            .then(
                (axiosResponse) => {
                    return axiosResponse.data;
                },
                (axiosError) => {
                    return axiosError?.response?.data || axiosError;
                }
            )
    }

    async post(url, body) {
        return axios.post(`${url}`, JSON.stringify(body), {
            headers: this.headers
        }).then(
            (axiosResponse) => {
                return axiosResponse.data;
            },
            (axiosError) => {
                return axiosError?.response?.data || axiosError;
            }
        )
    }
}