export const DASHBOARD_VIEW_PERMISSION = "dashboard:view";
export const LIVE_MAP_VIEW_PERMISSION = "map:view";
export const JOBS_VIEW_PERMISSION = "jobs:view";
export const JOBS_ACTION_MAP_PERMISSION = "jobs:action-map";
export const JOBS_ACTION_CANCEL_PERMISSION = "jobs:action-cancel";
export const JOBS_ACTION_DELETE_PERMISSION = "jobs:action-delete";
export const VEHICLES_VIEW_PERMISSION = "vehicles:view";
export const VEHICLES_ACTION_MAP_PERMISSION = "vehicles:action-map";
export const VEHICLES_ACTION_MANUAL_JOB_PERMISSION = "vehicles:action-manual-job";
export const VEHICLES_ACTION_EDIT_MODE_PERMISSION = "vehicles:action-edit-mode";
export const VEHICLES_ACTION_RESET_PERMISSION = "vehicles:action-reset";
export const VEHICLES_GET_POSITION_PERMISSION = "vehicles:get-position";
export const TWISTLOCKS_VIEW_PERMISSION = "twistlocks:view";
export const TWISTLOCKS_ACTION_CONFIRM_SAFE_PERMISSION = "twistlocks:action-confirm-safe";
export const ALARMS_VIEW_PERMISSION = "alarms:view";
export const ALARMS_ACTION_RESOLVE_PERMISSION = "alarms:action-resolve";
export const ALARMS_ACTION_DELETE_PERMISSION = "alarms:action-delete";
export const PARAMETERS_VIEW_PERMISSION = "parameters:view";
export const PARAMETERS_ACTION_EDIT_VALUES_PERMISSION = "parameters:action-edit-values";
export const USERS_VIEW_PERMISSION = "users:view";
export const USERS_ACTION_ADD_PERMISSION = "users:action-add";
export const USERS_ACTION_EDIT_PERMISSION = "users:action-edit";
export const USERS_ACTION_DELETE_PERMISSION = "users:action-delete";
export const HELP_VIEW_PERMISSION = "help:view";