import { useLocalStorage } from "./useLocalStorage";

export const useUser = () => {
    const { setItem, getItem } = useLocalStorage();

    const addUser = (user) => {
        setItem("USER_INFO", JSON.stringify(user));
    };

    const removeUser = () => {
        setItem("USER_INFO", "");
    };

    const getUser = () => {
        return getItem("USER_INFO");
    }

    const isAuthenticated = () => {
        return !!getItem("USER_INFO");
    };

    const getUserRole = () => {
        const user = JSON.parse(getItem("USER_INFO"));
        return user?.role;
    }

    const getUserPermissions = () => {
        const user = JSON.parse(getItem("USER_INFO"));
        return user?.permissions ?? [];
    }

    return { addUser, getUser, getUserRole, removeUser, isAuthenticated, getUserPermissions };
};