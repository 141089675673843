import "./HelpContent.css";
export default function OverviewSolutionPage() {
    return (
        <div className="help-content-container">
            <h5 id="about_avms"><b>About AVMS</b></h5>
            <p dir="auto">
                The AVMS (Automated Vehicle Management System) is a software application that helps to track and manage the movement of vehicles
                An Automated Vehicle Management System (AVMS) receives job orders from the Terminal Operating System (TOS). It translates these orders into missions and sends them to the Fleet Management System (FMS). The FMS then assigns these missions to autonomous trucks, equipped with LiDAR sensors, to execute the tasks. LiDAR sensors help the trucks navigate, avoid obstacles, and safely complete their jobs within the designated area.
            </p>

            <h5 dir="auto" id="purpose_of_manual"><b>Purpose of the Manual</b></h5>
            <p dir="auto">
            This manual serves as a comprehensive guide to help users effectively utilize AVMS, ensuring efficient and safe truck operations.
            </p>
        </div>
    );
}