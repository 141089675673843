import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import Iconify from 'components/iconify/Iconify';
import { useUser } from 'hooks/useUser';
import { getRoutesByPermissions } from 'routes';
import AuthService from 'services/auth.service';




const authService = new AuthService();
export default function LoginForm({ onErrorAlert }) {
  const history = useHistory();
  const { addUser } = useUser();

  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState({ username: null, password: null });
  const [disabled, setDisabled] = useState(true);

  const handleClick = () => {
    authService.login(login.username, login.password).then(response => {
      if (response.status === 202) {
        addUser(response.data);
        const appRoutes = getRoutesByPermissions(response.data.permissions)
        const path = appRoutes[0].layout + appRoutes[0].path
        history.push(path);
      } else {
        const message = response.data.message;
        onErrorAlert(message);
      }
    });
  };

  const onChange = ($event, name) => {
    const value = $event.target.value;
    setLogin((prev) => {
      const current = { ...prev, [name]: value };
      updateDisabled(current);
      return current;
    });
  }

  const updateDisabled = (login) => {
    const { username, password } = login;
    const isDisabled = [username, password].some(v => v === '' || v === null || v === undefined);
    setDisabled(isDisabled);
  }



  return (
    <>
      <Stack spacing={3}>
        <TextField label="Email address / Username"
          autoComplete='off'
          InputProps={{
            autoComplete: 'new-password'
          }}
          onChange={(e) => onChange(e, 'username')} value={login.username} />

        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password'
          }}
          value={login.password}
          onChange={(e) => onChange(e, 'password')}
        />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" style={{ marginTop: '1rem' }} disabled={disabled} onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
