import { Alert, Snackbar, Typography } from '@mui/material';

import useResponsive from 'hooks/useResponsive';
import { useState } from 'react';
import './Login.css';
import LoginForm from './LoginForm';

export default function Login() {
    const mdUp = useResponsive('up', 'md');

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const handleClose = () => {
        setOpen(false);
    }

    const handleErrorAlert = (message) => {
        setOpen(true);
        setErrorMessage(message);
    }

    return (
        <>
            <title> Login | Terberg </title>
            <div className='row login-container'>
                <div className='col-lg-6 col-md-6 col-sm-12 login-form'>
                    <div className='login-form-logo-container'>
                        <img src={require("assets/img/terberg-no-bg.png")} alt="terberg logo" />
                        <Typography variant="h5">
                            Autonomous Vehicle Management System
                        </Typography>
                    </div>

                    <LoginForm onErrorAlert={handleErrorAlert} />
                    <footer className="px-0 px-lg-3">
                        <nav>
                            <p className="copyright text-center">
                                © {new Date().getFullYear()}{" "}
                                <a href="/">Terberg</a> && <span>.&nbsp; All rights reserved</span>
                            </p>
                        </nav>
                    </footer>
                </div>
            </div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
