import { HttpService } from './http.service';

const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_GATEWAY_REST_ID = process.env.REACT_APP_AWS_GATEWAY_REST_ID;
const ENV = process.env.REACT_APP_ENV;

export default class PositionService {
    httpService = new HttpService();
    url = `https://${AWS_GATEWAY_REST_ID}.execute-api.${AWS_REGION}.amazonaws.com/${ENV}/positions`;
    async fetchAll() {
        return this.httpService.get(this.url);
    }

    async put(body) {
        return this.httpService.put(this.url, body);
    }
}