export function convertRadionToDegree(value) {
    return (value * (-180)) / Math.PI;
}

export function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
        ].join('-') +
        ' ' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':')
    );
}

export const pageCountDisplay = (length, page, rowsPerPage) => {
    const pages = length % rowsPerPage === 0 ? length / rowsPerPage : ((Math.floor(length / rowsPerPage)) + 1);
    return `${page + 1} of ${pages}`;
}

export const compareIgnoreCase = (str1, str2) => {
    return str1.toLowerCase() === str2.toLowerCase();
}