
import { Button, Container, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { getRoutesByPermissions } from "routes.js";

import AccountPopover from "components/Account/AccountPopover";
import NotificationsPopover from "components/Notification/NotificationsPopover";
import { useUser } from "hooks/useUser";
import './AdminNavbar.css';

function Header() {
  const location = useLocation();
  const { getUserPermissions } = useUser();
  const permissions = getUserPermissions()
  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    const routes = getRoutesByPermissions(permissions);
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  return (
    <Navbar sticky="top" bg="light" expand="lg">
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <div className="left-navbar-container">
          <NotificationsPopover />
          <AccountPopover />
        </div>
      </Container>
    </Navbar >
  );
}

export default Header;
