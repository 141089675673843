import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

const jobs = [...Array(24)].map((_, index) => ({
    job: faker.datatype.number(),
    vehicle: faker.vehicle.vin(),
    img: faker.image.imageUrl(),
    destination: sample(['TZW02', 'TZW03', 'TZW04', 'TZW05', 'TZW06', 'TZW07', 'TZW08']),
    longitude: faker.address.longitude(),
    latitude: faker.address.latitude(),
    status: sample([
        'Can be Created',
        'Sent',
        'Ongoing',
        'Completed',
        'Cancelled',
        'Rejected'
    ]),
    successor: faker.vehicle.vin(),
    created: faker.datatype.datetime(),
    updated: faker.datatype.datetime(),
    creator: faker.name.fullName(),
    updater: faker.name.fullName()
}));

export default jobs;