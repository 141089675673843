import { formatDistanceToNow } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import {
  Avatar,
  Badge,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';
// components
import Iconify from 'components/iconify/Iconify';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import AlarmService from 'services/alarm.service';
import ws from '../../services/webSocketConfig';

ws.onopen = (event) => {
  console.log("connection established !");
  return true;
};

function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}
const alarmService = new AlarmService();
export default function NotificationsPopover() {

  const history = useHistory();
  const [notifications, setNotifications] = useState([]);

  const total = notifications.length;

  const addNotificationIfNecessary = (alarm) => {
    if (alarm.status === 'OPEN') {
      const index = notifications.findIndex(notification => notification.id === alarm.id);
      if (index === -1) {
        const alarms = [...notifications];
        alarms.push(alarm);
        setNotifications(alarms);
        playSound();
      }
    } else {
      loadNotifications();
    }
  }
  ws.onmessage = (event) => {
    const data = JSON.parse(event.data);
    if (data?.type === 'ALARM') {
      addNotificationIfNecessary(data.data);
    }
    return true;
  }

  useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = () => {
    alarmService.fetchAll().then(data => {
      if (data.status === 200) {
        const filteredData = data.data.filter(alarm => alarm.status === 'OPEN')
        setNotifications([...filteredData]);
      }
    });
  }

  const playSound = () => {
    const sound = document.getElementById('notification-sound');
    if (sound) {
      sound.play();
    }
  }
  const goToAlarmsPage = () => {
    history.push('/admin/alarms');
  }

  return (
    <>
      <IconButton color={total > 0 ? 'primary' : 'default'} onClick={goToAlarmsPage} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={total} color="error">
          <Iconify icon="eva:bell-fill" />
          <audio id="notification-sound" src={require("assets/sound/notification-sound.mp3")} preload="auto"></audio>
        </Badge>
      </IconButton>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.name}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.description}
      </Typography>
    </Typography>
  );

  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
