import { Card, Container, FormControl, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, TextField } from "@mui/material";
import TableHeader from "components/TableHeader/TableHeader";

import Search from "components/Search/Search";
import Iconify from "components/iconify/Iconify";
import { filter } from "lodash";
import { useEffect, useState } from "react";

import CustomizeModal from 'components/Modal/CustomizeModal';

import SettingService from '../../services/setting.service';

import { ROWS_PERS_PAGE_OPTIONS } from "constants/COMMON";
import { PARAMETERS_ACTION_EDIT_VALUES_PERMISSION } from "constants/PERMISSIONS";
import { useUser } from "hooks/useUser";
import { pageCountDisplay } from "utils/utilFunctions";
import './Parameters.css';
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (item) =>
            item.id.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    };
    return stabilizedThis.map((el) => el[0]);
}

const TABLE_HEAD = [
    { id: 'id', label: 'Parameter', alignRight: false },
    { id: 'value', label: 'Value', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
];
const settingService = new SettingService();

export default function Parameters() {
    const { getUserPermissions } = useUser();
    const permissions = getUserPermissions();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PERS_PAGE_OPTIONS[0]);
    const [openEditPopup, setOpenEditPopup] = useState(false)
    const [order, setOrder] = useState('asc');

    const [orderBy, setOrderBy] = useState('id');

    const [filterQuery, setFilterQuery] = useState('');
    const [parameters, setParameters] = useState([]);
    const [filteredParameters, setFilteredParameters] = useState([]);
    useEffect(() => {
        settingService.fetchAll().then(data => {
            const filteredData = applySortFilter(data ?? [], getComparator(order, orderBy), filterQuery)
            const visibleData = filteredData.filter(item => item.visible);
            setParameters([...visibleData]);
            setFilteredParameters([...visibleData]);
        });
    }, []);

    useEffect(() => {
        const filteredData = applySortFilter(parameters, getComparator(order, orderBy), filterQuery);
        setFilteredParameters(filteredData);
    }, [order, orderBy, filterQuery])

    const sortAndFilterData = () => {
        const data = applySortFilter(parameters, getComparator(order, orderBy), filterQuery);
        setFilteredParameters([...data]);
    }

    const [currentParameter, setCurrentParameter] = useState({ id: '', value: null, description: '' })

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - parameters.length) : 0;

    const isNotFound = parameters.length === 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleOpenEditPopup = (parameter) => {
        setOpenEditPopup(true);
        setCurrentParameter({ id: parameter.id, value: parameter.value, description: parameter.description })
    }

    const handleCloseEditPopup = () => {
        setOpenEditPopup(false);
        setCurrentParameter({ id: '', value: null, description: '' });
    }

    const handleSaveParameter = () => {
        const setting = parameters.find(param => param.id = currentParameter.id);
        const payload = { ...currentParameter, visible: setting.visible };
        settingService.put(payload).then(() => {
            const data = parameters.map(parameter => {
                return parameter.id === currentParameter.id ? { ...currentParameter } : parameter;
            });
            setFilteredParameters([...data]);
            setParameters([...data]);
            setOpenEditPopup(false);
        });
    }

    const handleQueryFilter = (event) => {
        setPage(0);
        setFilterQuery(event.target.value);
        sortAndFilterData();
    };

    const handleParameterChange = ($event) => {
        const value = $event.target.value;
        setCurrentParameter((prev) => ({ ...prev, [$event.target.name]: value }));
    };

    return (
        <>
            <Container className="table-container">
                <Card>
                    <Search filterQuery={filterQuery} placeholder={'Search Parameters ...'} onFilterChange={handleQueryFilter}
                        isResetButtonVisible={true} />
                    <TableContainer>
                        <Table className="parameters-table" size='small' stickyHeader={true}>
                            <TableHeader
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={parameters.length}
                                onRequestSort={handleRequestSort}
                                isCheckBoxVisible={false}
                            />

                            <TableBody>
                                {filteredParameters.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { id, value, description } = row;
                                    return (
                                        <TableRow hover key={id}>
                                            <TableCell align="left" sx={{ width: 3 / 10 }}>{id}</TableCell>
                                            <TableCell align="left" sx={{ width: 4 / 10 }}>{value}</TableCell>
                                            <TableCell align="left" sx={{ width: 2 / 10 }} >{description}</TableCell>
                                            {permissions.includes(PARAMETERS_ACTION_EDIT_VALUES_PERMISSION) && <TableCell align="right">
                                                <IconButton size="large" color="inherit" onClick={() => handleOpenEditPopup(row)}>
                                                    <Iconify icon={'lucide:edit'} />
                                                </IconButton>
                                            </TableCell>}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                            {isNotFound && (
                                <TableBody>
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        className='table-pagination'
                        rowsPerPageOptions={ROWS_PERS_PAGE_OPTIONS}
                        component="div"
                        count={parameters.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={() => pageCountDisplay(parameters.length, page, rowsPerPage)}

                    />
                </Card>

            </Container >
            <CustomizeModal open={openEditPopup}
                title={'Edit Parameter'}
                handleClose={handleCloseEditPopup}
                handleSave={handleSaveParameter}
                isSaveDisabled={currentParameter.value === '' || currentParameter.value === null}
            >
                <form>
                    <div className='job-modal-container'>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='parameter-id-input'
                                type="text"
                                label='Parameter'
                                disabled={true}
                                value={currentParameter.id}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='parameter-value-input'
                                type="text"
                                label='Value'
                                name="value"
                                value={currentParameter.value}
                                onChange={handleParameterChange}
                                variant="outlined"
                            />
                        </FormControl>
                        <FormControl sx={{ m: 1 }}>
                            <TextField
                                id='parameter-description-input'
                                type="text"
                                label='Description'
                                name="description"
                                value={currentParameter.description}
                                onChange={handleParameterChange}
                                variant="outlined"
                                rows={3}
                                maxRows={7}
                                multiline
                            />
                        </FormControl>
                    </div>
                </form>
            </CustomizeModal>
        </>

    )

}