import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

const StyledDailog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs(propos) {
    const { children, title, open, handleClose, handleSave, isSaveDisabled } = propos;

    return (
        <div>
            <StyledDailog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle style={{ paddingLeft: '1.5rem' }}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    <Button disabled={isSaveDisabled} variant="contained" color='info' onClick={handleSave}>Save</Button>
                    <Button variant="contained" color='inherit' onClick={handleClose}>Discard</Button>
                </DialogActions>
            </StyledDailog>
        </div>
    );
}