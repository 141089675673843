import "./HelpContent.css";
export default function AlarmsAndNotificationsPage() {
    return (
        <div className="help-content-container">
            <p>Alarms are essential for alerting you to critical issues.</p>
            <p><img src={require("assets/img/alarmsAndNotificationsPage.png")} alt="ALarms and notification page" style={{ width: '80%' }} /></p>
            <h5><b>1. Bell icon</b></h5>
            <p>The number of alarms displayed on the bell icon at the top-right corner of the screen reflects the count of open alarms. These are alarms that have been created but not yet addressed or closed.</p>
            <h5><b>2. Timestamp Column</b></h5>
            <p>This column shows the exact date and time when an alarm event occurred. It's like a digital clock that records when something important or unusual happened within the system. Timestamps help you track when alarms were triggered, which is vital for understanding the sequence of events and diagnosing issues.</p>
            <h5><b>3. Alarm Code Column</b></h5>
            <p>The alarm code is like a secret language that gives you a quick idea of what type of alarm was triggered. Each alarm code represents a specific type of issue or event in the system. It's a way to categorize and label alarms, making it easier to identify and address different types of problems or notifications.</p>
            <h5><b>4. Status column</b></h5>
            <p>Indicates the current status of each alarm. If the status is "Open," it means the alarm has been created but not yet addressed. To mark an alarm as "Closed," you can do so manually by selecting the alarm and choosing the "Close" option. You can access this option by clicking the three dots on the right side of the alarm entry or by performing a right-click action. This change in status signifies that the alarm has been reviewed or resolved.</p>
            <h5><b>5. Entity Column</b></h5>
            <p>
                The Entity column in the Alarms section provides information about the cause of the alarm or the entity that is impacted by it. Depending on the source of the alarm, you'll see different identifiers in this column:<br></br>
                <pre style={{ whiteSpace: 'pre-line', fontSize: '15px' }}>
                    If the alarm is related to a vehicle, you will see the Vehicle ID <br></br>
                    If it's related to a job, the Job ID will be displayed<br></br>
                    For alarms originating from the system, you will see the System ID<br></br>
                </pre>
            </p>
            <h5><b>6. Details Column</b></h5>
            <p>The Details column provides additional information or a brief description of the alarm. It offers context to help users understand the nature of the issue or the event that triggered the alarm.</p>
        </div>
    )
}