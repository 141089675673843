//import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
// @mui
import {
    Card,
    Container,
    IconButton,
    MenuItem,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow
} from '@mui/material';

import Search from 'components/Search/Search';
import TableHeader from 'components/TableHeader/TableHeader';
import Iconify from 'components/iconify/Iconify';
import Label from 'components/label/Label';
import { ROWS_PERS_PAGE_OPTIONS } from 'constants/COMMON';
import { JOBS_ACTION_CANCEL_PERMISSION, JOBS_ACTION_DELETE_PERMISSION, JOBS_ACTION_MAP_PERMISSION } from 'constants/PERMISSIONS';
import { useUser } from 'hooks/useUser';
import JobsService from 'services/jobs.service';
import { pageCountDisplay } from 'utils/utilFunctions';
import './Jobs.scss';

const TABLE_HEAD = [
    { id: 'id', label: 'Job ID', alignRight: false },
    { id: 'vehicle', label: 'Vehicle', alignRight: false },
    { id: 'status', label: 'Progress', alignRight: false },
    { id: 'destinationId', label: 'Destination', alignRight: false },
    { id: 'destinationLon', label: 'Dest Lon', alignRight: false },
    { id: 'destinationLat', label: 'Dest Lat', alignRight: false },
    { id: 'predecessor', label: 'Predecessor', alignRight: false },
    { id: 'successor', label: 'Successor', alignRight: false },
    { id: 'fmsId', label: 'FMS ID', alignRight: false },
    { id: 'createdAt', label: 'Creater', alignRight: false },
    { id: 'creator', label: 'Creator', alignRight: false },
];
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (item) =>
            item.id.toLowerCase().indexOf(query.toLowerCase()) !== -1
            || item.vehicle.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1
            || item.fmsId && item.fmsId.toLowerCase().indexOf(query.toLowerCase()) !== -1
            || item.createdAt && item.createdAt.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1
            || item.creator && item.creator.toString().toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

function getColorByStatus(status) {
    switch (status) {
        case 'CREATED': return 'secondary';
        case 'ONGOING': return 'success';
        case 'VALIDATED': return 'primary';
        case 'COMPLETED': return 'default';
        case 'CANCELLED': return 'warning';
        case 'REJECTED': return 'error';
        default: return 'primary';
    }
}

export default function Jobs() {
    const { getUserPermissions } = useUser();
    const permissions = getUserPermissions();
    const jobsService = new JobsService();
    const history = useHistory();
    const [open, setOpen] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [filterJobs, setFilterJobs] = useState([]);

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [orderBy, setOrderBy] = useState('id');

    const [filterQuery, setFilterQuery] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PERS_PAGE_OPTIONS[0]);

    const [currentJob, setCurrentJob] = useState(null);


    useEffect(() => {
        jobsService.fetchAll().then(response => {
            setJobs(response);
            const filteredAndSortedJobs = applySortFilter(response, getComparator(order, orderBy), filterQuery);
            setFilterJobs(filteredAndSortedJobs);
        });
    }, []);

    useEffect(() => {
        const filteredAndSortedJobs = applySortFilter(jobs, getComparator(order, orderBy), filterQuery);
        setFilterJobs(filteredAndSortedJobs);
    }, [order, orderBy, filterQuery])

    const handleOpenMenu = (event, job) => {
        setOpen(event.currentTarget);
        setCurrentJob(job);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleQueryFilter = (event) => {
        const query = event.target.value;
        setPage(0);
        setFilterQuery(query);
        if (query === null || query === '') {
            setFilterJobs(jobs);
            return;
        }
        sortAndFilterData();
    };

    const sortAndFilterData = () => {
        const data = applySortFilter(jobs, getComparator(order, orderBy), filterQuery);
        setFilterJobs([...data]);
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobs.length) : 0;


    const isNotFound = jobs.length === 0;

    const handleCancelJob = () => {
        const payload = { type: 'CANCEL_JOB', body: JSON.stringify({ jobId: currentJob.id, status: currentJob.status }) };
        jobsService.put(payload).then((response) => {
            if (response.status === 200) {
                setCurrentJob(null);
                setOpen(false);
            } else {
                console.error(response);
            }
        });
    }

    const handleDeleteJob = () => {
        const jobId = currentJob.id;
        jobsService.delete(jobId).then(() => {
            const data = jobs.filter(job => job.id !== jobId);
            setJobs(data);
            setFilterJobs(data);
            setCurrentJob(null);
            setOpen(false);
        });
    }

    const goToMapView = () => {
        history.push('/admin/liveMap')
    }

    const rightClickHandler = (event, row) => {
        event.preventDefault();
        setOpen(event.target);
        setCurrentJob(row);
    }
    return (
        <>
            <title>Jobs table</title>

            <Container className='table-container'>
                <Card className='table-card'>
                    <Search filterQuery={filterQuery} placeholder={'Search job ...'} onFilterChange={handleQueryFilter} />
                    <TableContainer>
                        <Table className='jobs-table' size='small' stickyHeader={true}>
                            <TableHeader
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={jobs.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filterJobs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { id, vehicle, positionTosId, destinationLat, destinationLon, status, successor, predecessor, fmsId, createdAt, creator } = row;
                                    return (
                                        <TableRow hover key={id} onContextMenu={(event) => rightClickHandler(event, row)}>
                                            <TableCell align='left'>{id}</TableCell>

                                            <TableCell align="left">{vehicle}</TableCell>

                                            <TableCell align="left">
                                                <Label color={getColorByStatus(status)}>{status}</Label>
                                            </TableCell>

                                            <TableCell align="left">{positionTosId}</TableCell>

                                            <TableCell align="left">{destinationLat}</TableCell>
                                            <TableCell align="left">{destinationLon}</TableCell>
                                            <TableCell align="left">{predecessor}</TableCell>

                                            <TableCell align="left">{successor}</TableCell>
                                            <TableCell align="left">{fmsId}</TableCell>
                                            <TableCell align="left">{createdAt}</TableCell>
                                            <TableCell align="left">{creator}</TableCell>
                                            <TableCell align="right">
                                                <IconButton size="large" color="inherit" onClick={($event) => handleOpenMenu($event, row)}>
                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                            {isNotFound && (
                                <TableBody>
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>

                    <TablePagination
                        className='table-pagination'
                        rowsPerPageOptions={ROWS_PERS_PAGE_OPTIONS}
                        component="div"
                        count={jobs.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={() => pageCountDisplay(jobs.length, page, rowsPerPage)}

                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {permissions.includes(JOBS_ACTION_MAP_PERMISSION) && <MenuItem onClick={goToMapView} >
                    <Iconify icon={'eva:map-fill'} sx={{ mr: 2 }} />
                    Map
                </MenuItem>}
                {permissions.includes(JOBS_ACTION_CANCEL_PERMISSION) && currentJob?.status === 'ONGOING' &&
                    <MenuItem onClick={handleCancelJob}>
                        <Iconify icon={'eva:close-circle-fill'} sx={{ mr: 2 }} />
                        Cancel
                    </MenuItem>}
                {permissions.includes(JOBS_ACTION_DELETE_PERMISSION) && <MenuItem sx={{ color: 'error.main' }} onClick={handleDeleteJob}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>}
            </Popover>
        </>
    );
}
