import ReactDOM from "react-dom/client";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/demo.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";

import AdminLayout from "layouts/Admin.js";
import Login from "views/Login/Login";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path='/login' component={Login} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>
);
