import { Card, Container, IconButton, MenuItem, Popover, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow } from "@mui/material";
import Search from "components/Search/Search";
import TableHeader from "components/TableHeader/TableHeader";
import Iconify from "components/iconify/Iconify";
import Label from 'components/label/Label';
import { ROWS_PERS_PAGE_OPTIONS } from 'constants/COMMON';
import { ALARMS_ACTION_DELETE_PERMISSION, ALARMS_ACTION_RESOLVE_PERMISSION } from "constants/PERMISSIONS";
import { useUser } from "hooks/useUser";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import AlarmService from "services/alarm.service";
import { formatDate, pageCountDisplay } from './../../utils/utilFunctions';
import './Alarms.css';


const TABLE_HEAD = [
    { id: 'timestamp', label: 'Timestamp', alignRight: false },
    { id: 'code', label: 'Alarm Code', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'entity', label: 'Entity', alignRight: false },
    { id: 'severity', label: 'Severity', alignRight: false },
    { id: 'details', label: 'Details', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (item) =>
            item.code && item.code.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.entity && item.entity.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.status && item.status.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.severity && item.severity.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

function getColorByStatus(status) {
    switch (status) {
        case 'OPEN': return 'error';
        case 'CLOSED': return 'success';
        default: return 'error';
    }
}

function getColorBySeverity(status) {
    switch (status) {
        case 'MAJOR': return 'error';
        case 'MINOR': return 'warning';
        case 'INFO': return 'default';
        default: return 'default';
    }
}

const alarmService = new AlarmService();
export default function Alarms() {
    const { getUserPermissions } = useUser();
    const permissions = getUserPermissions();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('desc');

    const [orderBy, setOrderBy] = useState('timestamp');

    const [filterQuery, setFilterQuery] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PERS_PAGE_OPTIONS[0]);

    const [alarms, setAlarms] = useState([]);
    const [filterAlarms, setFilterAlarms] = useState([]);
    const [currentAlarm, setCurrentAlarm] = useState(null);

    useEffect(() => {
        alarmService.fetchAll().then(data => {
            if (data.status === 200) {
                const filteredData = applySortFilter(data.data ?? [], getComparator(order, orderBy), filterQuery)
                setAlarms([...filteredData]);
                setFilterAlarms([...filteredData]);
            }
        });
    }, []);

    useEffect(() => {
        const filteredData = applySortFilter(alarms, getComparator(order, orderBy), filterQuery);
        setFilterAlarms(filteredData);
    }, [order, orderBy, filterQuery])



    const handleOpenMenu = (event, alarm) => {
        setOpen(event.currentTarget);
        setCurrentAlarm(alarm);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleQueryFilter = (event) => {
        const query = event.target.value;
        setPage(0);
        setFilterQuery(query);
        if (query === null || query === '') {
            setFilterAlarms(alarms);
            return;
        }
        sortAndFilterData();
    };

    const sortAndFilterData = () => {
        const data = applySortFilter(alarms, getComparator(order, orderBy), filterQuery);
        setFilterAlarms([...data]);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - alarms.length) : 0;


    const isNotFound = alarms.length === 0;

    const handleAlarmClosed = () => {
        if (currentAlarm) {
            const updateAlarm = { ...currentAlarm, status: 'CLOSED' };
            alarmService.put(updateAlarm).then(() => {
                const data = alarms.filter(alarm => alarm.id !== updateAlarm.id);
                setAlarms(data);
                setFilterAlarms(data);
                setCurrentAlarm(null);
                setOpen(false);
            });
        }
    };

    const rightClickHandler = (event, row) => {
        event.preventDefault();
        setOpen(event.target);
        setCurrentAlarm(row);
    };

    return (
        <>
            <title>Alarms table</title>
            <Container className="table-container">
                <Card>
                    <Search filterQuery={filterQuery} placeholder={'Search alarms ...'} onFilterChange={handleQueryFilter}
                        isDeleteButtonVisible={true} />

                    <TableContainer>
                        <Table className="alarms-table" size='small' stickyHeader={true}>
                            <TableHeader
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={alarms.length}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {filterAlarms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    const { id, details, timestamp, code, entity, severity, status } = row;
                                    return (
                                        <TableRow hover key={id} onContextMenu={(event) => rightClickHandler(event, row)}>
                                            <TableCell align="left">{formatDate(new Date(timestamp))}</TableCell>
                                            <TableCell align='left'>{code}</TableCell>
                                            <TableCell align="left">
                                                <Label color={getColorByStatus(status)}>{status}</Label>
                                            </TableCell>
                                            <TableCell align='left'>{entity}</TableCell>
                                            <TableCell align="left">
                                                <Label color={getColorBySeverity(severity)}>{severity}</Label>
                                            </TableCell>
                                            <TableCell align="left">{details}</TableCell>
                                            <TableCell align="right">
                                                <IconButton size="large" color="inherit" onClick={($event) => handleOpenMenu($event, row)}>
                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                            {isNotFound && (
                                <TableBody>
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            )}

                        </Table>

                    </TableContainer>
                    <TablePagination
                        className='table-pagination'
                        rowsPerPageOptions={ROWS_PERS_PAGE_OPTIONS}
                        component="div"
                        count={alarms.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelDisplayedRows={() => pageCountDisplay(alarms.length, page, rowsPerPage)}

                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                {permissions.includes(ALARMS_ACTION_RESOLVE_PERMISSION) && <MenuItem onClick={handleAlarmClosed}>
                    <Iconify icon={'eva:checkmark-circle-2-fill'} sx={{ mr: 1, color: 'green' }} />
                    Close
                </MenuItem>}

                {permissions.includes(ALARMS_ACTION_DELETE_PERMISSION) && <MenuItem sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>}
            </Popover>
        </>
    )

}