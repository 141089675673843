import "./HelpContent.css";
export default function VehicleManagementPage() {
    return (
        <div className="help-content-container">
            <p>The Vehicle section offers real-time information about the automated trucks</p>
            <p><img src={require("assets/img/vehiclePage.png")} alt="Vehicle page" style={{ width: '80%' }} /></p>
            <h5><b>1. Vehicle ID Column</b></h5>
            <p>The Vehicle ID is a unique identifier for each autonomous truck within the AVMS. It serves as a reference point to distinguish one vehicle from another. This identification allows for efficient tracking and management of each truck's activities and status.</p>
            <h5><b>2. Mode Column</b></h5>
            <p>Automated trucks have different modes, including "Auto," "Off," "Manual," and "Remote." These modes control how the trucks operate.</p>
            <p><img src={require("assets/img/vehicleMode.png")} alt="Vehicle mode" style={{ width: '80%' }} /></p>
            <p><pre style={{ whiteSpace: 'pre-line', fontSize: '15px' }}>
                Please follow specific guidelines for each mode<br></br>

                If vehicle has job created or validated or sent or ongoing, return error<br></br>
                If user sets vehicle mode == OFF<br></br>
                Set vehicles status = UNAVAILABLE<br></br>

                If user sets mode = MANUAL<br></br>
                If vehicles status = AVAILABLE<br></br>
                Set vehicles status = UNAVAILABLE<br></br>

                If user sets mode = REMOTE<br></br>
                If vehicles status = AVAILABLE<br></br>
                Set vehicles status = UNAVAILABLE<br></br>

                If user sets mode = AUTO<br></br>
                If vehicles status = UNAVAILABLE<br></br>
                Set vehicles status = AVAILABLE<br></br>
            </pre>
            </p>
            <h5><b>3. Status Column</b></h5>
            <p>The Status column displays the current condition or state of each vehicle. It provides real-time information about what the truck is currently doing, whether it's on a mission, available for a new task, or facing any issues. Common statuses include "Driving," "Available," "UnAvailable," allowing you to monitor and manage the fleet effectively.</p>
            <h5><b>4. Autonomy</b></h5>
            <p>The Autonomy column, in this case, provides information about the battery status of each vehicle. It indicates the remaining power or charge available to the truck Understanding the battery status is essential to ensure that the vehicle can operate without interruption and complete its tasks successfully. This column helps in managing the power resources of each truck within the AVMS.</p>
            <h5><b>5. Handshake Column</b></h5>
            <p>The Handshake column reflects whether a truck is currently engaged in a handshake with the RTG crane, indicating that it's in the process of transferring cargo. When the Handshake status is "True," it means the truck is actively participating in this critical procedure and when it's "False," the truck is not involved in a handshake operation. Monitoring this status is crucial for ensuring secure cargo transfer and efficient communication within the AVMS.</p>
            <h5><b>6. TOS ID (Terminal Operating System Identification Number)</b></h5>
            <p>This column provides a unique identification number recognized by the Terminal Operating System (TOS) to distinguish each truck within the system.</p>
            <h5><b>7. FMS ID (VIN - Vehicle Identification Number)</b></h5>
            <p>This column offers an alternative identification method, using the Vehicle Identification Number (VIN), which is a globally recognized code assigned to each vehicle for recognition.</p>
            <h5><b>8. Get Vehicle Position</b></h5>
            <p>If user has the right privileges, an extra menu items shows the possibility to get the actual
                vehicle details (including position, speed…) and, if needed, use the GPS coordinates
                reported by FMS to override a Crane position.</p>
            <p><img src={require("assets/img/getVehiclePosition.png")} alt="Vehicle Manual job action" style={{ width: '80%' }} /></p>
            <p>The list of cranes shows all positions of type “CRANE” only.
                It is important to pay attention to the vehicle speed and capture timestamp before
                overriding a crane position to be sure the vehicle is stationary, and its coordinates are
                accurate enough.</p>
            <h5><b>9. Creating Jobs Manually</b></h5>
            <p>In some cases, you may need to create jobs manually. To do this, select the vehicle, access its options, choose "New Job," specify the destination, confirm the details, and submit. The AVMS will then assign the job to the chosen vehicle for execution.</p>
            <p><img src={require("assets/img/vehicleManualJob.png")} alt="Vehicle Manual job action" style={{ width: '80%' }} /></p>
            <h5><b>10. Reset vehicle</b></h5>
            <p>When you click on the "Reset" option for a specific vehicle, it triggers a process that restores the vehicle's status to "Available".</p>
        </div>
    )
};