import ChartistGraph from "react-chartist";
// react-bootstrap components
import {
  Card,
  Col,
  Container,
  Row
} from "react-bootstrap";
import "./Dashboard.scss";

function Dashboard() {
  return (
    <>
      <Container className="dashboard-container" fluid>
        <Row>
          <Col lg="4" sm="12">
            <Card className="card-stats dashboard-card">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Active jobs</p>
                      <Card.Title as="h4">5</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Update Now
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="4" sm="12">
            <Card className="card-stats dashboard-card">
              <Card.Body>
                <Row>
                  <Col xs="3">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-light-3 text-success"></i>
                    </div>
                  </Col>
                  <Col xs="9">
                    <div className="numbers">
                      <p className="card-category">Job time execution</p>
                      <Card.Title as="h4">1,345 Min</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Last day
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="4" sm="12">
            <Card className="card-stats dashboard-card">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Alerts</p>
                      <Card.Title as="h4">23</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats ">
                  <i className="far fa-clock mr-1"></i>
                  In the last week
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg="8" sm="12">
            <Card className="dashboard-card graph-card">
              <Card.Header>
                <Card.Title as="h4">Job types Behavior</Card.Title>
                <p className="card-category">24 Hours performance</p>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartHours">
                  <ChartistGraph
                    data={{
                      labels: [
                        "9:00AM",
                        "12:00AM",
                        "3:00PM",
                        "6:00PM",
                        "9:00PM",
                        "12:00PM",
                        "3:00AM",
                        "6:00AM",
                      ],
                      series: [
                        [287, 385, 490, 492, 554, 586, 698, 695],
                        [67, 152, 143, 240, 287, 335, 435, 437],
                        [23, 113, 67, 108, 190, 239, 307, 308],
                      ],
                    }}
                    type="Line"
                    options={{
                      low: 0,
                      high: 800,
                      showArea: false,
                      height: "200px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend dashboard-legend">
                  <i className="fas fa-circle text-info"></i>
                  Simple <i className="fas fa-circle text-danger"></i>
                  Split <i className="fas fa-circle text-warning"></i>
                  Manual Job
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-history mr-1"></i>
                  Updated 3 minutes ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="4" sm="12">
            <Card className="dashboard-card graph-card">
              <Card.Header>
                <Card.Title as="h4">Total jobs</Card.Title>
                <p className="card-category">Execution</p>
              </Card.Header>
              <Card.Body>
                <div
                  className="pie-chart-jobs"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: ["40%", "20%", "40%"],
                      series: [40, 20, 40],
                    }}
                    type="Pie"
                    options={{
                      fullWidth: true,
                      stretch: true,
                      height: "200px"
                    }}
                  />
                </div>
                <div className="legend dashboard-legend">
                  <i className="fas fa-circle text-info"></i>
                  Ongoing <i className="fas fa-circle text-success"></i>
                  Completed <i className="fas fa-circle text-warning"></i>
                  Rejected
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock mr-1"></i>
                  Last week
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
