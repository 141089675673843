import { Card, CardActions, CardContent, Typography } from "@mui/material";
import Slider from "components/Slider/Slider";
import { TWISTLOCKS_ACTION_CONFIRM_SAFE_PERMISSION } from "constants/PERMISSIONS";
import { useUser } from "hooks/useUser";
import { useEffect, useState } from "react";
import JobsService from "services/jobs.service";
import VehicleService from "services/vehicle.service";
import './Twistlocks.css';

export default function Twistlocks() {
    const vehicleService = new VehicleService();
    const jobsService = new JobsService();

    const { getUserPermissions, getUser } = useUser();
    const permissions = getUserPermissions();
    const userAsString = getUser();
    const user = JSON.parse(userAsString);

    const [vehicles, setVehicles] = useState([]);

    const getAllVehicles = async () => {
        const response = await vehicleService.fetchAll();
        setVehicles(response.filter(vehicle => vehicle.status === 'AWAITING_TWISTLOCKS'));
    }

    useEffect(() => {
        getAllVehicles();
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            getAllVehicles();
        }, 10000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    const handleChange = (vehicle) => {
        updateVehicle(vehicle);
    };

    const updateVehicle = (vehicle) => {
        const updatedVehicle = { ...vehicle, status: 'AVAILABLE' };
        const payload = { username: user.username, vehicle: updatedVehicle }
        vehicleService.put(payload).then(() => {
            setVehicles(prev => (prev.filter(v => v.id !== updatedVehicle.id)));
            publishTwistlockSuccessorJob(vehicle);
        });
    }

    const publishTwistlockSuccessorJob = (vehicle) => {
        const payload = {
            type: 'TWIST_LOCK_READY',
            body: JSON.stringify({ vehicleId: vehicle.id })
        };

        jobsService.post(payload).then(response => console.log(response));
    }

    return <div className="twistlocks-center">
        {vehicles.length === 0 ? (
            <Card className="no-vehicle-card">
                <Typography className="text">
                    No vehicle currently awaiting twistlock handling...
                </Typography>
            </Card>) :
            (<div className="twistlokcs-card-container">
                {
                    vehicles.map(vehicle =>
                        <Card className="twistlokcs-card">
                            <CardContent className="card-content">
                                <Typography variant="body2">
                                    Vehicle <strong>{vehicle.tosId} </strong> arrived and ready for twistlocks handling. Please confirm when done and at safe distance.
                                </Typography>
                            </CardContent>
                            {permissions.includes(TWISTLOCKS_ACTION_CONFIRM_SAFE_PERMISSION) && <CardActions>
                                <Slider onSuccess={() => handleChange(vehicle)} />
                            </CardActions>}
                        </Card>
                    )
                }
            </div>)
        }

    </div>
}